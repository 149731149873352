import xapp from '../../plugins/xapp'

const state = {}

const getters = {
    getNativeCurrency:  () => {
        const ottData = xapp.getter('tokenData')
        const isXah = ['XAHAU', 'XAHAUTESTNET'].includes(ottData?.nodetype)
        if(isXah) return 'XAH'
        else return 'XRP'
    }
}

const actions = {
    
}

const mutations = {
    
}

export default {
    state,
    getters,
    actions,
    mutations
}
