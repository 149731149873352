import axios from 'redaxios'
import xapp from './xapp'

const apiEndPoint = process.env.VUE_APP_API_ENDPOINT

const postNewTx = async (uuid, ticketIndex) => {
    try {
        if(!uuid || typeof uuid !== 'string') throw new Error('UUID parameter not of type string')
        const res = await axios.post(`${apiEndPoint}/api/v1/transaction/create/${uuid}`, { ticketIndex }, xapp.headers())
        return res.data
    } catch(e) {
        console.error(e)
        if(e?.data?.msg) throw e.data.msg
        throw 'undefined Error: creating new multisign request'
    }
}

const addSignedTxToList = async (txId, uuid) => {
    try {
        if(!txId) throw new Error('Transaction ID is required')
        if(typeof uuid !== 'string') throw new Error('UUID parameter is not of type string')

        const res = await axios.put(`${apiEndPoint}/api/v1/transaction/queue/${txId}`, { uuid }, xapp.headers())
        return res
    } catch(e) {
        console.error(e)
        if(e?.data?.msg) throw e.data.msg
        throw 'Error adding hash to TX'
    }
}

const getAccountInfo = async (account) => {
    if(!account) {
        throw 'No account given'
    }

    try {
        const res = await axios.get(`${apiEndPoint}/api/v1/account/${account}`, xapp.headers())
        return res.data
    } catch(e) {
        console.error(e)
        if(e?.data?.msg) throw e.data.msg
        throw 'Undefined Error getting MultiSign Account Data'
    }
}

const getTransactionById = async (id) => {
    if(!id || typeof id !== 'string') throw new Error('Id cant be: ' + id)

    try {
        const res = await axios.get(`${apiEndPoint}/api/v1/transaction/id/${id}`, xapp.headers())
        return res.data
    } catch(e) {
        console.error(e)
        if(e?.data?.msg) throw e.data.msg
        throw 'Undefined Error getting Transaction Data'
    } 
}

const getQueuedTxs = async (txIds, account) => {
    console.log('testing tx que')
    if(!txIds && !account) {
        throw new Error('Account or Array missing from paramaters')
    } 
    else if(!account) {
        if(!Array.isArray(txIds)) throw new Error('Not of type Array')
        if(txIds.length < 1) throw new Error('Array length < 1')
    } else if(!txIds) {
        if(typeof account !== 'string') throw new Error('Not a string')
    }

    try {
        const active = await axios.get(`${apiEndPoint}/api/v1/transaction/account/${account}`, xapp.headers())
        const history = await axios.get(`${apiEndPoint}/api/v1/transaction/account/${account}?result=true`, xapp.headers())
        return { active: active.data, history: history.data }
    } catch(e) {
        console.error(e)
        if(e?.data?.msg) throw e.data.msg
        throw 'Undefined Error getting queuedTxs'
    }
}

const submitMultiSignedTx = async (txId) => {
    console.log('submitting a tx with ID: ' + txId)
    if(!txId) throw new Error('Missing required param txid')
    if(typeof txId !== 'string') throw new Error('txId param is not of type string')

    try {
        const res = await axios.post(`${apiEndPoint}/api/v1/transaction/submit`, { txId }, xapp.headers())
        console.log(res.data)
        // if success return true
        return res.data
    } catch(e) {
        console.log('Error submitting tx with id')
        console.log(e)
        if(e?.data?.msg) throw e.data.msg
        throw 'Undefined Error when submitting transaction'
    }
}

export default {
    postNewTx,
    getAccountInfo,
    getTransactionById,
    getQueuedTxs,
    addSignedTxToList,
    submitMultiSignedTx
}