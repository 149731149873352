import multi_sign from '../../plugins/multi-sign'

const state = {
    multiSignAccountInfo: {},
    queuedTx: [],
    history: [],
    init: false
}

const getters = {
    getMultisignAccountInfo: state => {
        return state.multiSignAccountInfo
    },
    getQueuedTxs: (state, getters, rootState, rootGetters) => invalid => {
        const availableTickets = rootGetters.getAvailableTickets
        const AccountSequence = rootGetters.getAccountInfo.Sequence
        return state.queuedTx.filter(tx => {
            const ticket = tx.txjson.TicketSequence
            const seq = tx.txjson.Sequence
    
            let isValid
            if (ticket > 0) isValid = availableTickets.includes(ticket)
            else isValid = seq === AccountSequence
            return invalid ? !isValid : isValid
        })
    },
    getHistoryTransactions: state => {
        return state.history
    },
    getQueuedTransactionsBySequence: state => seq => {
        if(!seq) return []
        return state.queuedTx.filter(tx => tx.txjson.TicketSequence === seq || tx.txjson.Sequence === seq)
    },
    getTransactionById: (state, getters) => id => {
        const tx = state.queuedTx.find(txn => txn._id === id)
        return tx ? tx : getters.getHistoryTransactions.find(txn => txn._id === id) || null
    },
    usedTicketSequences: state => {
        return state.queuedTx
            .filter(item => item.txjson.TicketSequence && !item.submit_result)
            .map(item => item.txjson.TicketSequence)
            .sort()
    },
    getNextTicketSequence: (state, getters, rootState, rootGetters) => {
        let avail = rootGetters.getAvailableTickets
            .filter(TicketSequence => {
                for(const usedSequence of getters.usedTicketSequences) {
                    if(TicketSequence === usedSequence) return false
                }
                return true
            })
        if(avail.length < 1 || !avail) {
            return null
        }
        return avail[0]
    },
    getSequenceStatus: (state, getters, rootState, rootGetters) => seq => {
        if(isNaN(seq)) return NaN
        if(rootGetters.getAccountInfo.Sequence == seq) return 'Not a Ticket, Using Sequence'
        if(getters.usedTicketSequences.filter(x => x === seq).length > 1) return 'Ticket is also used in another transaction'
        if(!rootGetters.getAvailableTickets.includes(seq) && !(seq > rootGetters.getAccountInfo.Sequence)) {
            return !rootGetters.getAvailableTickets.includes(seq) ? 'TicketSequence Invalid' : 'Sequence Invalid'
        }
        if(seq > rootGetters.getAccountInfo.Sequence) return 'Using Future Sequence, Current #' + rootGetters.getAccountInfo.Sequence
        else return false
    }
}

const actions = {
    // backend stuff
    getMultisignAccountInfo: async (context, account) => {
        account = context.rootGetters.getAccount
        const res = await multi_sign.getAccountInfo(account)
        return context.commit('setMultiSignAccountInfo', res)
    },
    setQueuedTxs: async (context, account) => {
        account = context.rootGetters.getAccount
        const res = await multi_sign.getQueuedTxs(null, account)
        return context.commit('setQueuedTxs', res)
    },
    getTransactionById: async (context, id) => {
        const res = await multi_sign.getTransactionById(id)
        context.dispatch('addQueuedTx', res)
        return res
    },
    addQueuedTx: (context, tx) => {
        context.commit('addQueuedTx', tx)
    },
    updateQueuedTx: (context, tx) => {
        context.commit('updateQueuedTx', tx)
    }
}

const mutations = {
    setMultiSignAccountInfo: (state, obj) => {
        if(process.env.VUE_APP_DEV_MODE == 'TRUE') console.log(obj)
        state.multiSignAccountInfo = obj
    },
    setQueuedTxs: (state, array) => {
        if(process.env.VUE_APP_DEV_MODE == 'TRUE') console.log(array)
        state.queuedTx = array.active
        state.history = array.history
    },
    addQueuedTx: (state, tx) => {
        const txIndex = state.queuedTx.findIndex(item => item._id === tx.id)
        if(txIndex < 0) state.queuedTx.push(tx)
        else state.queuedTx[txIndex] = tx
    },
    updateQueuedTx: (state, updatedTx) => {
        const index = state.queuedTx.findIndex(tx => 
            tx._id === updatedTx._id || (tx.reject_tx && tx.reject_tx._id === updatedTx._id)
        );

        if (index !== -1) {
            // Check if the transaction is a reject_tx
            if (state.queuedTx[index].reject_tx && state.queuedTx[index].reject_tx._id === updatedTx._id) {
                state.queuedTx[index].reject_tx = updatedTx;
            } else {
                state.queuedTx.splice(index, 1, updatedTx);
            }
        } else {
            // Optionally handle the case where the transaction doesn't exist
            console.log("Transaction not found for update");
        }
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
