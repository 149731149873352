<template>
    <div id="overlay-picker-transaction-type">
        <nav class="transaction-header-row">
            <a class="btn" @click="close" style="width: 40px;">
                <fa :icon="['fas', 'chevron-left']"/>
            </a>
            <h2 style="margin: 0 auto;">Select Transaction</h2>
            <a style="width: 40px;"></a>
        </nav>
        <div class="container">
            <ul class="cards-list">
                <li v-for="option in options" @click="select(option)">
                    <img :src="`/images/TransactionTypes/${option}.png`">
                    <h4>{{ option }}</h4>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
defineProps(['options'])
const emit = defineEmits(['selected', 'close'])

const select = (value) => {
    emit('selected', value)
}

const close = (value) => {
    emit('close')
}
</script>

<style scoped>
#overlay-picker-transaction-type {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--var-bg-color);
    z-index: 5;
}
.container {
    padding: 10px;
}
nav {
    display: flex;
    flex-direction: row;
    align-items: center;
}
ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
li {
    cursor: pointer;
    font-size: 1.2rem;
}
.cards-list > li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--grey2);
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    width: 150px;
    padding: 5px;
    margin: 0 auto;
}
.light .cards-list > li {
    background-color: rgba(0, 0, 0, 0.05);
}
.cards-list > li > img {
    height: 50px;
    padding: 10px 0;
}
</style>
