import { createRouter, createWebHistory } from 'vue-router'

import HomeScreen from '../components/HomeScreen.vue'
import Setup from '../views/Setup.vue'
import Transaction from '../views/Transaction.vue'
import AuthenticateVue from '../components/SignIn.vue'
import AboutPage from '../views/About.vue'

import xapp from '../plugins/xapp'
import jwt_decode from "jwt-decode"

let routedByPush = false

const routes = [
    {
        path: '/',
        name: 'Home',
        component: {
            template: '<router-view/>',
        },
        beforeEnter: async (to, from, next) => {
            const data = await xapp.getTokenData()
            const token = jwt_decode(data.token)

            switch(token.access) {
                case 'restricted':
                    return next('/signin')
                case 'login':
                    return next('/auth')
                case 'full':
                    return next('/transactions')
            }
            next('/error')
        }
    },
    {
        path: '/auth',
        name: 'Authenticate',
        component: AuthenticateVue,
        beforeEnter: async (to, from, next) => {
            const data = await xapp.getTokenData()
            const token = jwt_decode(data.token)
            // check if the token is valid
            switch(token.access) {
                case 'restricted':
                    return next('/signin')
                case 'login':
                    return next()
                case 'full':
                    return next('/transactions')
            }
            next()
        }  
    },
    {
        path: '/transactions',
        name: 'Transactions',
        component: HomeScreen,
        beforeEnter: async (to, from, next) => {
            const data = await xapp.getTokenData()
            if(!routedByPush && Object.hasOwn(data, 'origin') && data?.origin?.data?.next) {
                routedByPush = true
                return next('/transaction/view/' + data?.origin?.data?.next)
            }
            next()
        }
    },
    {
        path: '/transaction/compose',
        name: 'Compose',
        component: Transaction,
        props: { comp: 'compose' }
    },
    {
        path: '/transaction/view/:id',
        name: 'ViewTx',
        component: Transaction,
        props: route => ({ txId: route.params.id })
    },
    {
        path: '/signin',
        name: 'signin',
        component: Setup
    },
    {
        path: '/setup',
        name: 'setup',
        component: () => import('../components/SetupSignerList.vue')
    },
    {
        path: '/about',
        name: 'About',
        component: AboutPage
    },
    {
        path: '/error',
        name: 'Error',
        component: () => import('../views/Error.vue')
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('../views/404.vue')
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
