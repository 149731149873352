<template>
    <form v-if="view === 'compose'">
        <h2 style="text-align: center;">Create # Tickets</h2>
        <div class="btn-options-row">
            <ValueSelector :balance="0" :value="tickets" @selected="setTickets"/>
        </div>
        <!-- <fieldset>
            <label>TicketCount</label>
            <input type="number" inputmode="numeric" :min="min" :max="max" v-model="tickets" @keydown="prevent"/>
        </fieldset> -->
        <p v-if="!ticketsAvailable">
            You currently have no available tickets. To facilitate MultiSign on the XRPL, it's essential to create tickets. These tickets are used to propose transactions to the signer list, ensuring the proper functioning of multi-signature processes.
        </p>
        <p v-else-if="ticketsAvailable === 1">
            There is currently 1 available ticket for this transaction. Consider creating more tickets for future use.
        </p>
        <p v-else>
            You have {{ ticketsAvailable }} available tickets for MultiSign transactions.
        </p>
        <SpinnerButton class="btn btn-success push-top" @click="checkBeforeSend()">{{ $t('buttons.submit') }}</SpinnerButton>
    </form>

    <div class="transaction-view" v-else-if="view === 'view'">
        <h4>Transaction Summary</h4>
        <span>The amount of Tickets created: {{ transaction.txjson.TicketCount }}</span>
        <span>Ranging Sequence Numbers: {{ transaction.txjson.Sequence }} - {{ transaction.txjson.Sequence + transaction.txjson.TicketCount }}</span>
        <span>Fees Paid: {{ transaction.txjson.Fee }} drops</span>
    </div>

    <div v-else-if="view === 'listItem'" class="transaction-list-item">
        <span>TicketCount: {{ transaction.txjson.TicketCount }}</span>
    </div>

</template>

<script>
// https://xrpl.org/ticketcreate.html

import xapp from '../../plugins/xapp'
import mixin from '../../mixins/multiSign'
import multiSign from '../../plugins/multi-sign'
import ValueSelector from '../ValueSelector.vue'

export default {
    components: { ValueSelector },
    props: {
        view: {
            validator(value) {
                return ['compose', 'view', 'listItem'].includes(value);
            }
        },
        transaction: Object
    },
    mixins: [mixin],
    data() {
        return {
            TransactionType: 'TicketCreate',
            Fee: "200",
            tickets: null,
            min: 2,
            max: 255,
            step: 1
        };
    },
    computed: {
        txjson() {
            if (!this.NextTicketSequence) {
                return {
                    TransactionType: this.TransactionType,
                    Account: this.$store.getters.getAccount,
                    Sequence: this.$store.getters.getAccountInfo.Sequence,
                    TicketCount: this.tickets
                };
            }
            else {
                return {
                    TransactionType: this.TransactionType,
                    Account: this.$store.getters.getAccount,
                    TicketCount: this.tickets,
                    TicketSequence: this.NextTicketSequence,
                    Sequence: 0,
                    // LastLedgerSequence: null
                };
            }
        },
        ticketsAvailable() {
            const unique = this.$store.getters.getUnusedTickets
            return unique.length
        }
    },
    methods: {
        setTickets(value) {
            const amount = parseInt(value)
            if(isNaN(amount) || !amount >= 1) alert('Error with selecting the tickets value is: ' + amount + 'type' + typeof amount)
            this.tickets = amount
        },
        checkBeforeSend() {
            if(this.txjson.TicketCount <= 0 || !this.txjson.TicketCount) return alert('Select an amount of Tickets')
            return this.sendTx(this.txjson)
        },
        prevent(e) {
            if (e.key === 'ArrowLeft')
                return;
            if (e.key === 'ArrowRight')
                return;
            if (!/^[0-9]$/i.test(e.key)) {
                switch (e.key) {
                    case 'Home':
                    case 'End':
                    case 'Shift':
                    case 'Control':
                    case 'Escape':
                    case 'Alt':
                    case 'Meta':
                    case 'Tab':
                    case 'Backspace':
                    case 'Delete':
                    case 'Enter':
                    case 'ArrowLeft':
                    case 'ArrowRight':
                        break;
                    case '.':
                    case ',':
                        if (Number.isInteger(this.step))
                            return e.preventDefault();
                        break;
                    default:
                        console.log(`Prevent key: ${e.key}`);
                        return e.preventDefault();
                }
            }
            const val = e.target.value, idx = e.target.selectionStart, key = e.key;
            const value = val.slice(0, idx) + key + val.slice(idx + Math.abs(0));
            // if(value)
        }
    }
}
</script>

<style scoped>
.transaction-view {
    display: flex;
    flex-direction: column;
}
.transaction-view span {
    font-size: 0.9rem;
}
.btn-options-row {
    display: flex;
    flex-direction: column;
    align-content: center;
}
</style>