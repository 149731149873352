<template>
    <div class="container">
        <div class="multisign-account-box">
            <code>{{ $store.getters.getAccount }}</code>
        </div>

        <div class="body-has-multisign">
            <p>{{ $t('login.body') }}</p>
            <h3 style="text-align: center;">{{ $t('login.header') }}</h3>
            <hr>
            <ul>
                <li v-for="item in signerList.SignerEntries">
                    <code style="font-size: 0.8rem; text-align: center;">{{ item.SignerEntry.Account }}</code>
                    <SpinnerButton class="btn btn-primary" @click.prevent="signIn(item.SignerEntry.Account)">
                        <img src="../assets/svg/chevron-left.svg" style="transform: rotate(180deg);"/>
                    </SpinnerButton>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import xapp from '../plugins/xapp'

export default {
    emits: ['accessChange'],
    computed: {
        signerList() {
            return this.$store.getters.getSignerList
        },
        signerArray() {
            return this.$store.getters.getSignerListArray
        }
    },
    methods: {
        async signIn(signer) {
            if(process.env.VUE_APP_DEV_MODE == 'TRUE') {
                this.$emit('accessChange', 'full')
                this.$router.replace('/transactions')
                return 
            }
            try {
                const array = [...this.signerArray]
                const index = array.indexOf(signer)
                if (index > -1) {
                    array.splice(index, 1)
                    array.unshift(signer)
                }

                const res = await xapp.authenticateBySignerLogin(array)
                this.$emit('accessChange', res.access)
                this.$router.replace('/transactions')
            } catch(e) {
                if(e === 'DECLINED') return null
                alert('error getting authTokens...')
            }
        }
    }
}
</script>

<style scoped>
ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
li {
    background-color: var(--var-secondary);
    border-radius: 5px;
    padding: 5px;
    margin: 20px 0;
    text-align: center;
    display: flex;
    align-items: center;
}
.body-has-multisign {
    padding: 15px;
}
.multisign-account-box {
    background-color: rgb(239, 193, 97);
    color: black;
    font-size: 0.8rem;
    border-radius: 5px;
    /* width: fit-content; */
    margin: 0 auto;
    padding: 5px;
}
.btn-primary {
    max-width: 50px;
    margin-left: auto;
    background-color: var(--blue);
}
</style>