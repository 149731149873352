<template>
    <form v-if="view === 'compose'">
        <h2 style="text-align: center;">Create a TrustLine</h2>
        <hr style="margin-bottom: 20px;">
        <h6 style="">Issuer</h6>
        <fieldset style="background-color: var(--grey4); border-radius: 10px; padding: 5px;">
            <code style="margin-right: auto; margin-left: auto;">{{ LimitAmount?.issuer ? `${LimitAmount.issuer.slice(0,8)}...${LimitAmount.issuer.slice(-8)}` : 'Scan Issuer Account' }}</code>
            <a @click="scanQR()" class="btn btn-primary" style="width: 40px; height: 40px;">
                <img src="../../assets/svg/camera.viewfinder.svg" style="filter: invert(1); width: 25px;">
            </a>
        </fieldset>
        <code style="margin-bottom: 20px; color: var(--grey); font-size: 0.7rem;">https://xrpl.org?issuer=rxxx&amp;currency=USD&amp;value=1000000000</code>

        <!-- MEMO component -->
        <h3>TrustLine Flags</h3>
        <table style="margin: 0; padding: 0;">
            <colgroup>
                <col style="width: calc(100% - 30px);">
                <col style="width: 30px;">
            </colgroup>
            <template v-for="flag of tfOptions">
                <tr>
                    <td>
                        <label>{{ flag[0].slice(2)}}</label>
                    </td>
                    <td style="padding: 5px 0;">
                        <input type="checkbox" v-model="Flags" :value="flag[0]">
                    </td>
                </tr>
            </template>
        </table>
        <SpinnerButton class="btn btn-success push-top" @click="sendTx(txjson)">{{ $t('buttons.submit') }}</SpinnerButton>
    </form>

    <div v-else-if="view === 'view'" class="column">
        <table>
            <tr>
                <td>Issuer:</td>
                <td>
                    <code>{{ compressString(transaction.txjson.LimitAmount.issuer, 8) }}</code>
                </td>
            </tr>
            <tr>
                <td>Currency:</td>
                <td>
                    <code>{{ transaction.txjson.LimitAmount.currency }}</code></td>
            </tr>
            <tr>
                <td>Value:</td>
                <td class="number">{{ transaction.txjson.LimitAmount.value }}</td>
            </tr>
            <tr>
                <td>Flags:</td>
                <td>{{ parseFlags(transaction.txjson.Flags) }}</td>
            </tr>
        </table>
    </div>

    <div v-else-if="view === 'listItem'" class="column">
        <table>
            <tr>
                <td>Issuer:</td>
                <td>
                    <code>{{ compressString(transaction.txjson.LimitAmount.issuer, 8) }}</code>
                </td>
            </tr>
            <tr>
                <td>Currency:</td>
                <td>
                    <code>{{ transaction.txjson.LimitAmount.currency }}</code></td>
            </tr>
            <tr>
                <td>Value:</td>
                <td class="number">{{ transaction.txjson.LimitAmount.value }}</td>
            </tr>
            <tr>
                <td>Flags:</td>
                <td>{{ parseFlags(transaction.txjson.Flags) }}</td>
            </tr>
        </table>
    </div>
</template>

<script>
// https://xrpl.org/payment.html#payment
// https://xumm.app/?issuer=rhub8VRN55s94qWKDv6jmDy1pUykJzF3wq&currency=USD QR EXAMPLE

import xapp from '../../plugins/xapp'
import mixin from '../../mixins/multiSign'

import { compressString } from '../../plugins/number-format'

import flagFunctions from '../../plugins/flags'


export default {
    props: {
        view: {
            validator(value) {
                return ['compose', 'view', 'listItem'].includes(value)
            }
        },
        transaction: Object
    },
    mixins: [mixin],
    data() {
        return {
            TransactionType: 'TrustSet',
            LimitAmount: {
                currency: "USD",
                issuer: "rhub8VRN55s94qWKDv6jmDy1pUykJzF3wq",
                value: "1000000000"
            },
            Flags: []
        }
    },
    computed: {
        txjson() {
            if(!this.NextTicketSequence) return null
            return {
                TransactionType: this.TransactionType,
                Account: this.$store.getters.getAccount,
                LimitAmount: this.LimitAmount,
                Flags: flagFunctions.setFlags(this.Flags, 'TrustSet'),
                TicketSequence: this.NextTicketSequence,
                Sequence: 0
            }
        },
        tfOptions() {
            return flagFunctions.getFlagOptions('TrustSet')
        }
    },
    methods: {
        compressString,
        valueUpdate(value) {
            this.LimitAmount.value = value
        },
        currencyChange(value) {
            console.log(value)
            console.log('Currency change')
        },
        async scanQR() {
            try {
                const result = await xapp.openScanner()
                if(!result.hasOwnProperty('qrContents')) throw 'No content'
                const qString = new URL(result.qrContents).searchParams
                const params = new URLSearchParams(qString)
                this.LimitAmount.issuer = params.get('issuer')
                this.LimitAmount.currency = params.get('currency')
                this.LimitAmount.value = params.get('value') || '1000000000'
            } catch(e) {
                console.log(e)
            }
        },
        parseFlags(flags) {
            const flagArray = flagFunctions.parseFlags(flags, 'AccountSet')
            return flagArray
        }
    }
}
</script>

<style scoped>
table {
    border-collapse: collapse;
}
td {
    padding: 0; 
    margin: 0;
}
</style>
