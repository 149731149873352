<template>
    <Spinner v-show="!ready && !error"/>
    <template v-if="isDevMode">
        <span style="color: red;" @click="refetch()">{{ loadingState }} - SEQ#{{ $store.getters.getAccountInfo?.Sequence }}</span>
    </template>

    <div v-if="error" class="column h-100">
        <div id="failed-start" class="column">
            <fa :icon="['fas', 'exclamation-circle']" />
            <p>{{ error }}</p>
            <a @click="if($t('xapp.error.get_ott_data') === error) getTokenData(); else if($t('xapp.error.subscribe_to_account') === error) subscribe();" class="btn btn-primary">{{ $t("xapp.button.try_again") }}</a>
        </div>
    </div>
    <template v-else-if="ready && !error">
        <span v-if="isDevMode">{{ $route.fullPath }}</span>

        <RouterView v-slot="{ Component }">
            <KeepAlive :include="['SetupSignerList']">
                <component :is="Component" />
            </KeepAlive>
        </RouterView>
    </template>

    <Modal />
    <Alert />
</template>

<script>
import Alert from '@/components/Alert.vue'
import Modal from '@/components/Modal.vue'
import Spinner from '@/components/Spinner.vue'

import xapp from './plugins/xapp'
import client from './plugins/ws-client'

import jwt_decode from 'jwt-decode'
import router from './router'
import { RouterView } from 'vue-router'

export default {
    components: {
    Alert,
    Modal,
    Spinner,
    router,
    RouterView
},
    data() {
		return {
			ready: false,
            login: false,
			error: false,
            loadingState: 'nill'
		}
	},
	computed: {
		account() {
			return this.$store.getters.getAccount
		},
        isDevMode() {
            return process?.env?.VUE_APP_DEV_MODE?.toLowerCase() === 'true'
        }
	},
	methods: {
        refetch() {
            console.log('reFetching account data...')
            this.getMultiSignAccountInfo()
            this.$store.dispatch('setAccount', this.$store.getters.getAccount)
        },
        async getMultiSignAccountInfo() {
            console.log('getting info')
            await this.$store.dispatch('getMultisignAccountInfo')
            console.log('we have the info')
        },
		async wsConnect(data) {
            try {
                if(!data) data = await xapp.getTokenData()
                client.connect(data.nodewss, { NoUserAgent: true, MaxConnectTryCount: 5 })
                await this.$store.dispatch('setAccount', data.account)
                this.error = false
            } catch(e) {
                this.error = this.$t('xapp.error.subscribe_to_ledger')
                alert(this.error)
                throw e
            }
        }
	},
	async mounted() {
		try {
            this.loadingState = 'Retrieving Token...'
            const data = await xapp.getTokenData()

            if (xapp.versionCheck(data.version, '2.3.0') < 0) {
                throw this.$t('xapp.error.version')
            }

            this.loadingState = 'Received OTT data, connecting to wss: ' + data?.nodetype

            await this.wsConnect(data)

            this.loadingState = 'Connected to wss: ' + data?.nodetype

            const token = jwt_decode(data.token)
            if(token.hasMultiSign === false) {
                // this.$router.replace('/setup')
            } else if(token.access === 'login') {
                this.login = true
                // this.$router.replace('/auth')
            } else {
                this.loadingState = 'Retrieving: MultiSign INFO'
                await this.getMultiSignAccountInfo()
            }
		} catch(e) {
            return this.error = e
        }
        this.ready = true

        this.loadingState = 'READY'

        try {
            client.send({
                command: 'subscribe',
                accounts: [this.$store.getters.getAccount]
            })
        } catch(e) {
            this.error = this.$t('xapp.error.subscribe_to_account')
            alert(this.error)
        }

		client.on('transaction', tx => {
            console.log('Receving transaction', tx)
            // for(const node in tx.meta.AffectedNodes) {
            //     if(node.DeletedNode.LedgerEntryType === 'Ticket') this.$store.dispatch('deleteTicketByIndex', node.DeletedNode.LedgerIndex)
            // }

            // Next line is used to parse account subscribe txn
			this.$store.dispatch('parseTx', { transaction: tx, notify: true })
            this.refetch()
		})
	},
    beforeUnmount() {
        this.$emitter.all.clear()
    },
    errorCaptured(err, vm, info) {
      console.error('VUEJS error:', err)
      console.error('VUEJS errorInfo:', info)
      if(typeof err === 'string') alert(err)
      else alert(JSON.stringify(err))
  }
}
</script>

<style>
@import url('https://use.typekit.net/iqo4nny.css');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Condensed:wght@600&display=swap');
@import URL('https://use.typekit.net/rav4uns.css');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;300;400;500;600;700&display=swap');


#app {
	font-family: proxima-nova, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: auto;

    background-color: var(--var-bg-color);
    color: var(--var-txt-color);
    user-select: none;
    display: flex;
    flex-direction: column;
    height: 100%;
}
#view {
    height: 100%;
    overflow: hidden;
    background-color: var(--var-bg-color);
    color: var(--var-txt-color);
    user-select: none;
}
#failed-start {
    align-items: center;
    margin: auto;
}
code {
    font-family: 'Roboto Mono', monospace;
    font-size: 0.9rem;
}
.number {
    font-family: 'IBM Plex Sans Condensed';
    font-family: din-2014, sans-serif;
    /* font-family: din-condensed,sans-serif; */
    font-variant-numeric: tabular-nums;
    font-weight: 400;
    font-style: normal;
    line-height: 1;
    white-space: nowrap;
}
.row {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 5px 0;
    align-items: center;
}
.column {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.h-100 {
    height: 100%;
}
.margin-0 {
    margin: 0 !important;
}
.push-top {
    margin-top: auto;
}
.btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 40px;
    border-radius: 5px;
    width: 100%;
    cursor: pointer;
    font-weight: 600;
    font-size: 1rem;
    text-align: center;
    color: var(--var-txt-color);
}
.tabs-row {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    width: max-content;
    font-weight: bold;
    justify-content: space-between;
    gap: 15px;
    margin: 10px auto;
}
.tabs-row input {
    display: none;
}
.tabs-row > label {
    cursor: pointer;
    padding: 5px 10px;
    border: 1px solid var(--grey);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    background-color: transparent;
    color: var(--grey);
}
.tabs-row label.checked {
    background-color: var(--blue);
    color: white;
    border-color: transparent;
}
fieldset {
    display: flex;
    align-items: center;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-block-start: 0;
    padding-inline-start: 0;
    padding-inline-end: 0;
    padding-block-end: 0;
    min-inline-size: min-content;
    border-width: 0;
    border-style: none;
    border-color: rgba(255, 255, 255, 0);
    border-image: none;
}

/* TODO CLEAN */
.margin-input {
    margin-top: 2px !important;
}
.padding {
    margin: 0 5px;
    width: calc(100% - 10px) !important;
}
span.dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: white;
    display: inline-block;
}
h1 {
    margin: 0;
}
h2 {
    margin: 0;
}
h3 {
    margin: 0;
    font-size: 16px;
}
h4 {
    margin: 0;
}
h5 {
    margin: 0;
    font-size: 14px;
}
h6 {
    margin: 0;
    font-size: 12px;
}
.align-end {
    text-align: end;
}
hr {
    /* display: block;
    unicode-bidi: isolate;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    margin-inline-start: auto;
    margin-inline-end: auto;
    overflow: hidden;
    border-style: solid;
    border-width: 1px; */
    margin: 0;
    border: 0;
    border-top: 1px solid var(--var-border);
    width: 100%;
}
.container {
    display: flex;
    flex-direction: column;
    width: auto;
    height: 100%;
    align-items: center;
}
.btn-primary {
    background-color: var(--var-primary);
    color: white;
}
.btn-secondary {
    background-color: var(--var-secondary) !important;
    color: grey;
}
.btn-success {
    background-color: var(--green);
    color: white;
}
.btn-cancel {
    background-color: var(--red);
    color: white;
}
.btn-warning {
    background-color: var(--orange);
    color: white;
}
.header {
    margin: 0 10px;
    margin-bottom: 0 !important;
    text-align: center;
    color: var(--var-primary);
}
.input-error {
    border-color: red !important;
}
.input-label {
    /* width: 100%; */
    text-align: center;
    padding: 0 10px;
    height: 30px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid var(--var-border);
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.input-label:focus-within {
    border: 1px solid var(--var-primary);
}
.input-label label {
    position: absolute;
    top: 0;
    right: 8px;
    bottom: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: var(--var-bg-color); */
}
.input-label input {
    color: var(--var-txt-color);
    background-color: var(--var-bg-color);
    border: 0;
    padding: 0;
    /* text-align-last: center; */
    width: calc(100% - 8px);
    font-family: 'IBM Plex Sans Condensed';
    font-size: 1rem;
}
.input-label input:focus {
    outline: 0;
}
.input-label input::-webkit-input-placeholder {
    font-family: proxima-nova, sans-serif !important;
}
select {
    -webkit-appearance: none;
    /* text-align: center;
    text-align-last: center; */
    width: 100%;
    color: var(--var-txt-color);
    background-color: var(--var-bg-color);
    padding: 0 10px;
    height: 28px;
    font-size: 0.8rem;
    border-radius: 5px;
    border: 1px solid var(--var-border);
}
select.arrow {
    background-image:
        linear-gradient(45deg, transparent 50%, gray 50%),
        linear-gradient(135deg, gray 50%, transparent 50%);
    background-position:
        calc(100% - 20px) calc(1em + 2px),
        calc(100% - 15px) calc(1em + 2px);
    background-size:
        5px 5px,
        5px 5px;
    background-repeat: no-repeat;
}
select:focus {
    border: 1px solid var(--var-primary);
}
select.arrow:focus {
  background-image:
    linear-gradient(45deg, gray 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, gray 50%);
  background-position:
    calc(100% - 15px) 1em,
    calc(100% - 20px) 1em;
  background-size:
    5px 5px,
    5px 5px;
  background-repeat: no-repeat;
  outline: 0;
}
.dark .invert-color {
    filter: invert(1)
}
</style>
