<template>
    <form v-if="view === 'compose'" class="container">
        <div class="signer-list-settings-container">
            <div>
                <ToolTip :info="$t('tooltips.quorum')">
                    <h6>{{ $t('headers.quorum') }}</h6>
                </ToolTip>
                <div class="weight-selector">
                    <a v-if="editMode" class="weight-select-btn" :class="{ disabled: quorum <= 1 }">
                        <img src="../../assets/svg/minus-circle-red.svg" @click="changeQuorum(-1)">
                    </a>
                    <h1>{{ quorum || 0 }}</h1>
                    <a v-if="editMode" class="weight-select-btn" :class="{ disabled: quorum >= combinedSignerWeight }">
                        <img src="../../assets/svg/plus-circle-fill.svg" @click="changeQuorum(1)">
                    </a>
                </div>
            </div>
            <div>
                <h6>{{ $t('headers.total_weight') }}</h6>
                <h1>{{ combinedSignerWeight || 0 }}</h1>
            </div>
            <div>
                <h6>Total Signers</h6>
                <h1>{{ SignersLength || 0 }}</h1>
            </div>
            <div v-show="activeSignerList">
                <h6>{{ $t('headers.reset_signer_list') }}</h6>
                <a class="btn" style="width: 40px;" @click="reset()">
                    <img src="../../assets/svg/reset-icon.svg" class="invert-color">
                </a>
            </div>
        </div>

        <a class="btn btn-primary" v-if="!editMode" @click="editMode = true">Edit</a>
        <SpinnerButton class="btn btn-primary" @click.prevent="SignerListSet()" v-else>{{ $t('buttons.submit') }}</SpinnerButton>
        
        <ul class="signer-list">
            <li class="signer-item" v-for="(signer, index) in SignerEntries" :key="signer" :signer="signer" style="padding-left: 0;">
                <a @click="removeSignerFromList(index)" class="btn" style="width: 45px;">
                    <img src="../../assets/svg/xmark-circle-fill.svg"/>
                </a>
                <div class="signer-item-account">
                    <!-- <div style="display: flex; flex-direction: row; gap: 5px;">
                        <label class="signer-label">LABELS</label>
                    </div> -->
                    <h4>{{ signer.name || 'Signer' }}</h4>
                    <code style="text-align: start;">{{ compressString(signer.account, 6) }}</code>
                </div>
                <div class="signer-item-actions">
                    <h6 style="text-align: center;">Weight</h6>
                    <div class="weight-selector">
                        <a class="weight-select-btn" :class="{ disabled: signer.weight <= 1 }">
                            <img src="../../assets/svg/minus-circle-red.svg" @click="decreaseWeight(signer)" v-show="editMode">
                        </a>
                        <h2 style="margin: auto;">{{ signer.weight }}</h2>
                        <a class="weight-select-btn">
                            <img src="../../assets/svg/plus-circle-fill.svg" @click="increaseWeight(signer)" v-show="editMode">
                        </a>
                    </div>
                </div>
            </li>
        </ul>
        <SpinnerButton v-if="editMode && SignersLength < 32" class="btn btn-outline" @click.prevent="addSigner()"><img src="../../assets/svg/plus-circle-fill.svg" style="margin: 0 10px;">Add Signer</SpinnerButton>
    </form>

    <div v-else-if="view === 'view'">
        <div class="signer-list-settings-container" style="justify-content: space-between;">
            <div style="flex-basis: auto;">
                <h6>Signers</h6>
                <h1>{{ transaction?.txjson?.SignerEntries?.length }}</h1>
            </div>
            <div style="flex-basis: auto;">
                <h6>Quorum</h6>
                <h1>{{ transaction?.txjson?.SignerQuorum }}</h1>
            </div>
            <div style="flex-basis: auto;">
                <h6>Total Weight</h6>
                <h1>{{ calculatedSignerWeightTx }}</h1>
            </div>
        </div>
        <ul class="signer-list">
            <li class="signer-item" v-for="(signer, index) in transaction?.txjson?.SignerEntries" :key="signer" :signer="signer">
                <div class="signer-item-account">
                    <h4>Signer</h4>
                    <code style="text-align: start;">{{ compressString(signer?.SignerEntry?.Account, 6) }}</code>
                </div>
                <div class="signer-item-actions">
                    <h6 style="text-align: center;">Weight</h6>
                    <div class="weight-selector">
                        <h2 style="margin: auto;">{{ signer?.SignerEntry?.SignerWeight }}</h2>
                    </div>
                </div>
            </li>
        </ul>
    </div>

    <div v-else-if="view === 'listItem'" style="display: flex; flex-direction: row; align-items: center; justify-content: space-between; padding-top: 10px;">
        <h6>Signers: {{ transaction?.txjson?.SignerEntries?.length }}</h6>
        <h6>Quorum: {{ transaction?.txjson?.SignerQuorum }}</h6>
        <h6>Weight: {{ calculatedSignerWeightTx }}</h6>
    </div>
</template>

<script>
import xapp from '../../plugins/xapp'
import { compressString } from '../../plugins/number-format'
import mixin from '../../mixins/multiSign'
import client from '../../plugins/ws-client'

export default {
    props: {
        view: {
            validator(value) {
                return ['compose', 'view', 'listItem'].includes(value)
            }
        },
        sign: {
            validator(value) {
                return ['direct'].includes(value)
            }
        },
        transaction: Object
    },
    mixins: [mixin],
    emits: ['result'],
    data() {
        return {
            TransactionType: 'SignerListSet',
            editMode: true,
            editSigner: false,
            signerEditIndex: 0,
            changes: false,
            quorum: null,
            SignerEntries: []
        }
    },
    computed: {
        combinedSignerWeight() {
            let weight = 0
            this.SignerEntries.forEach(signer => {
                weight += signer.weight
            })
            return weight
        },
        calculatedSignerWeightTx() {
            let weight = 0
            this.transaction?.txjson?.SignerEntries?.forEach(signer => {
                weight += Number(signer?.SignerEntry?.SignerWeight)
            })
            return weight
        },
        SignersLength() {
            return this.SignerEntries.length
        },
        activeSignerList() {
            return this.$store.getters.getSignerList.SignerEntries
        }
    },
    methods: {
        compressString,
        changeQuorum(value) {
            if(value === -1) {
                if(this.quorum <= 1) return
                this.quorum -= 1
            } else if(value === 1) {
                if(this.combinedSignerWeight <= this.quorum) return
                this.quorum += 1
            } else {
                console.log('Why change value so much, tranquillo')
            }
        },
        increaseWeight(signer) {
            signer.weight++
        },
        decreaseWeight(signer) {
            if(signer.weight <= 1) return
            signer.weight--
        },
        async addSigner() {
            const maxSigners = 32
            if(this.SignerEntries.length >= maxSigners) {
                return alert('Reached a maximum signer list of: ' + this.SignerEntries.length)
            }

            try {
                const result = await xapp.destinationSelect()
                if(!result.destination || result.destination === null) return null
                // todo check result for blackhole etc...
                await this.signerCheck(result.destination.address)
                this.addSignerTolist(result.destination)
            } catch (e) {
                return alert(typeof e === 'string' ? e : 'Error Selecting Signer')
            }
            // Get account info if blackhole label, if not activated label etc...
        },
        async signerCheck(account) {
            if(this.$store.getters.getAccount === account) throw this.$t('setup.signer_is_account')
            for(const entry of this.SignerEntries) {
                if(entry.account === account) throw this.$t('setup.signer_in_list')
            }
            const info = await client.send({
                command: 'account_info',
                account: account
            })
            if(Object.hasOwn(info, 'error')) {
                if(info.error === 'actNotFound') {
                    // if not activated account the signer is allowed on the list only show a warning
                    alert(this.$t('setup.singer_not_activated'))
                }
                else throw info.error_message || info.error
            } else {
                const flags = info.account_flags
                if(flags.disableMasterKey === true) throw this.$t('setup.signer_master_disabled')
            }
            return true
        },
        addSignerTolist(signer) {
            this.SignerEntries.push({
                name: signer.name,
                account: signer.address,
                weight: 1
            })
        },
        removeSignerFromList(index) {
            this.SignerEntries.splice(index, 1)
        },
        async SignerListSet() {
            try {
                if(this.SignerEntries.length < 1) throw 'At least add one Signer to the list'
                // list greater than 0 smaller than 32 if list is 0 delete the signer list but only if the account has master not disabled or regular key
                const SignerEntries = this.SignerEntries.map(item => {
                    return {
                        SignerEntry: {
                            Account: item.account,
                            SignerWeight: item.weight
                        }
                    }
                })

                console.log('sign \'SignerListSet\' tx')
                const SignerListSet = {
                    TransactionType: 'SignerListSet',
                    Account: this.$store.getters.getAccount,
                    SignerQuorum: this.quorum,
                    SignerEntries: SignerEntries
                }

                if(isNaN(this.quorum) || !Number.isInteger(this.quorum)) throw 'Quorum is not a number'
                if(this.quorum < 1) throw 'Quorum setting can\'t be smaller then 1'
                if(this.quorum > this.combinedSignerWeight) throw 'Quorum setting can\'t be higher than SignerWeight'

                if(this.sign === 'direct') return this.signPayloadDirect(SignerListSet)
                else return this.sendTx(SignerListSet)
            } catch(e) {
                alert(e)
            }
        },
        async signPayloadDirect(txjson) {
            try {
                const result = await xapp.signPayload({
                    txjson: txjson,
                    options: {
                        signers: this.$store.getters.getAccount
                    }
                })
                if(result.response.dispatched_result === 'tesSUCCESS') {
                    this.$emit('result', result)
                } else {
                    alert(`Result is: ${result.response.dispatched_result}, please check error code bedore trying again or contact support`)
                }
            } catch(e) {
                if(e === 'DECLINED') return 
                alert('Error creating signerlist')
            }
        },
        reset() {
            this.initList()
        },
        initList() {
            const list = this.activeSignerList
            const quorum = this.$store.getters.getSignerList.SignerQuorum
            if(list) {
                this.SignerEntries = list.map(item => {
                    return {
                        name: null,
                        account: item.SignerEntry.Account,
                        weight: item.SignerEntry.SignerWeight
                    }
                })
            }
            if(quorum) this.quorum = quorum
            else this.editMode = true
        }
    },
    mounted() {
        this.initList()
    }
}
</script>

<style scoped>
.container {
    padding: 0 15px;
}
nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
nav > a {
    max-width: 40px;
}
.signer-list-settings-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.signer-list-settings-container > div {
    width: 100px;
    flex-basis: calc(50% - 10px);
    margin: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
@media (min-width: 500px) {
    .signer-list-settings-container {
        flex-wrap: nowrap;
        margin: 0 auto;
    }
    .signer-list-settings-container > div {
        flex-basis: none;
    }
}
.signer-list-settings-container > div > *:last-child {
    margin: auto;
}
/* List */
ul {
    margin: 10px 0;
    padding: 0;
    gap: 10px;
    width: 100%;
}
.signer-list {
    display: flex;
    flex-direction: column;
}
/* .signer-label {
    border: 2px solid rgb(59, 130, 247);
    background-color: rgba(59, 131, 247, 0.5);
    border-radius: 5px;
    font-size: 0.8rem;
    font-weight: bold;
    padding: 1px 5px;
} */
.signer-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px;
    border-radius: 5px;
    background-color: var(--grey5);
    padding: 10px;
}
.signer-item-account {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
}
.signer-item-actions {
    min-width: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.action-row {
    display: flex;
    flex-direction: row;
    margin-left: auto;
}
.weight-selector {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.weight-selector > h2 {
    min-width: 25px;
    text-align: center;
}
.weight-selector > h1 {
    min-width: 35px;
    text-align: center;
}
.weight-select-btn {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    margin: auto;
    justify-content: center;
    scale: 1.2;
    cursor: pointer;
}
.weight-select-btn.disabled > img {
    filter: grayscale(100%);
}
.btn-outline {
    border: 2px solid var(--grey4);
    background-color: rgb(255, 255, 255, 0.05);
}
</style>