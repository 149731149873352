<template>
    <div class="selector-row">
        <div v-for="option in options" @click="$emit('selected', option.value)" :key="option" class="selector-btn" :class="{ selected: option.value === value }">
            {{ option.label }}
        </div>
    </div>    
</template>

<script>
export default {
    props: ['balance', 'value'],
    emit: ['selected'],
    computed: {
        options() {
            // todo check account reserve * tickets is available with account balance...
            return [{ value: 5, label: '5' }, { value: 10, label: '10' }, { value: 50, label: '50' }]
        }
    }
}
</script>

<style scoped>
.selector-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: space-between; */
    gap: 20px;
    margin: 0 auto;
}
.selector-btn {
    border: 2px solid var(--var-border);
    border-radius: 5px;
    color: white;
    padding: 5px 10px;
    background-color: var(--var-secondary);
    min-width: 50px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
}
.selector-btn.selected {
    border-color: var(--var-txt-color);
    background-color: var(--blue);
}
</style>
