import xapp from '../plugins/xapp'
import multiSign from '../plugins/multi-sign'
import client from '../plugins/ws-client'
import fee from '../plugins/fee'

const mixin = {
    computed: {
        SignerQuorum() {
            return this.$store.getters.getAccountInfo?.signer_lists?.[0]?.SignerQuorum
        },
        NextTicketSequence() {
            return this.$store.getters.getNextTicketSequence
        }
    },
    methods: {
        getSignerWeightCombined(txs) {
            const signersArray = txs.map(item => item.signer)
            return this.$store.getters.getSignerWeightCombined(signersArray)
        },
        async sendTx(txjson, route = true) {
            let TicketHash
            if(isNaN(txjson.TicketSequence)) {
                try {
                    await this.$store.dispatch('setAccountInfo')
                    if(!Object.hasOwn(txjson, 'Sequence')) txjson['Sequence'] = this.$store.getters.getAccountInfo.Sequence
                } catch (e) {
                    alert('issue with getting account data before sign and submit')
                }
            } else {
                TicketHash = this.$store.getters.getTicketHashBySequence(txjson.TicketSequence)
                txjson.Sequence = 0
            }
            if(!Object.hasOwn(txjson, 'Account')) txjson['Account'] = this.$store.getters.getAccount

            if(!txjson.hasOwnProperty('Fee') || isNaN(txjson.Fee)) {
                const calcFee = fee.calculateFees(await client.send({ command: 'fee' }))
                txjson.Fee = Number(calcFee.fee_medium) * 8
            }

            const ottData = await xapp.getTokenData()
            const network = ottData.nodetype
            if(!Object.hasOwn(txjson, 'NetworkID') && ['XAHAU', 'XAHAUTESTNET'].includes(network)) {
                switch(network) {
                    case 'XAHAU':
                        txjson['NetworkID'] = 21337
                        break
                    case 'XAHAUTESTNET':
                        txjson['NetworkID'] = 21338
                        break
                }
            }

            let uuid
            try {
                uuid = await xapp.signPayload({
                    txjson: txjson,
                    options: {
                        multisign: true,
                        submit: false,
                        signers: this.$store.getters.getSignerListArray
                    }
                }, true)
            } catch(e) {
                if(e !== 'DECLINED') {
                    alert(this.$t('signing.error'))
                }
                return
            }

            try {
                const dbResult = await multiSign.postNewTx(uuid, TicketHash)
                this.$store.dispatch('addQueuedTx', dbResult)
                route ? this.$router.push('/transaction/view/' + dbResult._id) : await this.$store.dispatch('setQueuedTxs')
            } catch(e) {
                console.log(e)
                if(e) alert(e)
                else alert(this.$t('backend.create_tx_error'))
            }
        }
    }
}
export default mixin