<template>
    <div class="multisign-account-box" @click="showExpanded = !showExpanded" :style="{ 'border-radius': showExpanded ? '15px 15px 0 0' : null }">
        <div class="account-pill">
            <a>
                <img src="../assets/png/keyword.png" style="width: 20px; height: 20px;">
            </a>
            <code>{{ compressString($store.getters.getAccount, 5) }}</code>
            <a>
                <img src="../assets/svg/chevron-left.svg" :style="{ transform: showExpanded ? 'rotate(90deg)' : 'rotate(-90deg)' }" style="scale: 0.8;" class="invert-color">
            </a>
        </div>

        <div v-if="showExpanded" id="dropdown">
            <ul>
                <li>
                    <h6>SignerList</h6>
                    <h6>{{ $t('headers.weight') }}</h6>
                </li>
                <li v-for="signer in signerList">
                    <code>{{ compressString(signer.SignerEntry.Account, 5) }}</code>
                    <span style="font-size: 1.1rem; font-weight: bold;">{{ signer.SignerEntry.SignerWeight }}x</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import { compressString } from '../plugins/number-format'

import { ref, computed, inject } from 'vue'
const store = inject('store')

const showExpanded = ref(false)

const signerList = computed(() => {
    return store.getters.getSignerList?.SignerEntries || []
})

console.log(signerList)
</script>

<style scoped>
.multisign-account-box {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: var(--grey4);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 15px;
}
.account-pill {
    height: 30px;
    width: 200px;
    max-width: 200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
}
.account-pill > a {
    width: 40px;
    max-width: 40px;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
}
#dropdown {
    position: absolute;
    z-index: 2;
    top: 31px;
    left: 0;
    right: 0;
    background-color: inherit;
    border-radius: 0 0 15px 15px;
    border-top: 1px solid transparent;
    padding: 10px 15px;
}
ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
li {
    display: flex;
    flex-direction: row;
    align-items: center;
}
li > *:first-child {
    flex: 1;
}
</style>
