<template>
    <form v-if="view === 'compose'">
        <fieldset>
            <label style="font-weight: bold;">Delete #</label>
            <select class="arrow" v-model="TicketSequence">
                <option :value="sequence" v-for="sequence of availableTickets">{{ sequence }}</option>
            </select>
        </fieldset>
        <SpinnerButton class="btn btn-success push-top" @click="validateBeforeSend()">{{ $t('buttons.submit') }}</SpinnerButton>
    </form>

    <div v-else-if="view === 'view'">
        <span>Delete Ticket: {{ transaction.txjson.TicketSequence }}</span>
    </div>

    <div v-else-if="view === 'listItem'">
        <span>Delete Ticket: {{ transaction.txjson.TicketSequence }}</span>
    </div>
</template>

<script>
// https://xrpl.org/about-canceling-a-transaction.html
// "no-op" transaction -> cancels the sequence

import xapp from '../../plugins/xapp'
import mixin from '../../mixins/multiSign'
import multiSign from '../../plugins/multi-sign'

export default {
    props: {
        view: {
            validator(value) {
                return ['compose', 'view', 'listItem'].includes(value)
            }
        },
        transaction: Object
    },
    mixins: [mixin],
    data() {
        return {
            // no-op
            TransactionType: 'AccountSet',
            TicketSequence: null
        }
    },
    computed: {
        availableTickets() {
            return this.$store.getters.getAvailableTickets
        },
        txjson() {
            return {
                TransactionType: this.TransactionType,
                TicketSequence: this.TicketSequence
            }
        }
    },
    methods: {
        validateBeforeSend() {
            if(!Number.isInteger(this.txjson.TicketSequence)) throw alert('Please select a TicketSequence to delete')
            this.sendTx(this.txjson)
        }
    }
}
</script>

<style scoped>

</style>
