<template>
    <form v-if="view === 'compose'">
        <div>
            Account settings:

            <span v-for="flag of accountFlags">{{ flag }}</span>
            <span v-if="accountFlags.length < 1">No account Flags set</span>
        </div>

        <div class="input-label" :class="{ 'input-error': domainInputError }">
            <input type="url" placeholder="https://test.com" v-model="Domain">
            <label>Domain</label>
        </div>

        <div class="input-label" :class="{ 'input-error': emailInputError }">
            <input type="email" placeholder="test@test.com" v-model="EmailHash">
            <label>Email</label>
        </div>
        <SpinnerButton class="btn btn-success push-top" @click="prepareTx()">{{ $t('buttons.submit') }}</SpinnerButton>

        <h3>Account Set Flags (asf)</h3>
        <table style="margin: 0; padding: 0;">
            <colgroup>
                <col style="width: max-content;">
                <col style="width: max-content;">
            </colgroup>
            <template v-for="flag of asfOptions">
                <tr v-if="flag[0] !== 'asfDisableMaster' || (flag[0] === 'asfDisableMaster' && flag[2])">
                    <td>
                        <label>{{ flag[0].slice(3)}}</label>
                    </td>
                    <td style="padding: 5px 0;">
                        <SpinnerButton class="btn" :class="{ 'edit-btn': !flag[2], 'btn-primary': flag[2] }" @click.prevent="setFlag(flag[0], flag[2])">{{ flag[2] ? 'Clear' : 'Set' }}</SpinnerButton>
                    </td>
                </tr>
            </template>
        </table>

        <!-- <fieldset >
            <label>asfRequireDest</label>
            {{ AccountFlags.lsfRequireDestTag }}
            <SpinnerButton class="btn edit-btn" @click.prevent="setFlag('asfRequireDest')">Enable</SpinnerButton>
        </fieldset> -->
    </form>

    <div v-else-if="view === 'view'">
        <div class="column">
            <span v-if="transaction.txjson.Domain">Domain: {{ hexToStr(transaction.txjson.Domain) }}</span>
            <span v-if="transaction.txjson.EmailHash">Email: {{ transaction.txjson.EmailHash }}</span>
            <span v-if="transaction.txjson.MessageKey">MessageKey: {{ transaction.txjson.MessageKey }}</span>
            <span v-if="transaction.txjson.ClearFlags" v-for="flag of parseFlags(transaction.txjson.ClearFlag)">ClearFlag: {{ flag }}</span>
            <span v-if="transaction.txjson.SetFlag" v-for="flag of parseFlags(transaction.txjson.SetFlag)">SetFlag: {{ flag }}</span>
            <span v-if="transaction.txjson.Flags" v-for="flag of parseFlags(transaction.txjson.Flags)">TransactionFlag: {{ flag }}</span>

            <span v-if="transaction.txjson.TransferRate">TransferRate: {{ transaction.txjson.TransferRate }}</span>
            <span v-if="transaction.txjson.TickSize">TickSize: {{ transaction.txjson.TickSize }}</span>
        </div>
    </div>

    <div v-else-if="view === 'listItem'">
        <span v-if="transaction.txjson.Domain">Domain: {{ hexToStr(transaction.txjson.Domain) }}</span>
        <span v-if="transaction.txjson.EmailHash">Email: {{ transaction.txjson.EmailHash }}</span>
        <span v-if="transaction.txjson.MessageKey">MessageKey: {{ transaction.txjson.MessageKey }}</span>
        <span v-if="transaction.txjson.ClearFlags" v-for="flag of parseFlags(transaction.txjson.ClearFlag)">ClearFlag: {{ flag }}</span>
        <span v-if="transaction.txjson.SetFlag" v-for="flag of parseFlags(transaction.txjson.SetFlag)">SetFlag: {{ flag }}</span>
        <span v-if="transaction.txjson.Flags" v-for="flag of parseFlags(transaction.txjson.Flags)">TransactionFlag: {{ flag }}</span>

        <span v-if="transaction.txjson.TransferRate">TransferRate: {{ transaction.txjson.TransferRate }}</span>
        <span v-if="transaction.txjson.TickSize">TickSize: {{ transaction.txjson.TickSize }}</span>
    </div>
</template>

<script>
// https://xrpl.org/accountset.html#accountset

import flagFunctions from '../../plugins/flags'
import mixin from '../../mixins/multiSign'
import { strToHex, hexToStr } from '../../plugins/number-format'
import md5 from 'md5'

export default {
    props: {
        view: {
            validator(value) {
                return ['compose', 'view', 'listItem'].includes(value)
            }
        },
        transaction: Object
    },
    mixins: [mixin],
    data() {
        return {
            TransactionType: 'AccountSet',
            Domain: null,
            EmailHash: null,
            emailInputError: false,
            domainInputError: false
        }
    },
    computed: {
        accountFlags() {
            const acc = this.$store.getters.getAccountInfo
            const array = flagFunctions.parseFlags(acc.Flags, 'AccountRoot').map(flag => flag.slice(3))
            return array
        },
        asfOptions() {
            return flagFunctions.getFlagOptions('AccountSet').filter(flag => flag[0].slice(0,3) === 'asf').map(flag => {
                const flag_set = this.accountFlags.includes(flag[0].slice(3))
                flag[2] = flag_set
                return flag
            })
        }
    },
    methods: {
        hexToStr,
        async prepareTx() {
            const txjson = {
                TransactionType: this.TransactionType,
                TicketSequence: this.NextTicketSequence
            }
            if(this.Domain) txjson['Domain'] = strToHex(this.Domain)
            if(this.EmailHash) txjson['EmailHash'] = md5(this.EmailHash.trim().toLowerCase())
            await this.sendTx(txjson)
        },
        async setFlag(flag, isClear) {
            const flagInt = flagFunctions.setFlags([flag], 'AccountSet')
            if(isNaN(flagInt)) throw alert('Something is wrong with the flag')

            await this.sendTx({
                TransactionType: 'AccountSet',
                TicketSequence: this.NextTicketSequence,
                [isClear === true ? 'ClearFlag' : 'SetFlag']: flagInt
            })
        },
        parseFlags(flags) {
            const flagArray = flagFunctions.parseFlags(flags, 'AccountSet')
            return flagArray
        }
    }
}
</script>

<style scoped>
form {
    gap: 15px;
}
fieldset {
    padding: 0 10px;
    justify-content: space-between;
}
.edit-btn {
    border: 1px solid var(--var-border);
}
</style>
