<template>
    <div class="bar bar-container">
        <div class="bar bar-progress" :style="{ width: width, 'border-radius': progress < 1 ? '4px 0 0 4px' : '4px', 'background-color': progress >= threshold ? barColorThreshold : barColor }"></div>
        <div class="marker" v-show="threshold > 0 && threshold < 1" :style="{ left: `${threshold * 100}%` }"></div>
    </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';
const props = defineProps({
    progress: {
        validator(value) {
            return !isNaN(value) && value !== null
        }
    },
    threshold: {
        validator(value) {
            return !isNaN(value) && value !== null
        }
    },
    barColor: {
        type: String,
        default: 'var(--blue)'
    },
    barColorThreshold: {
        type: String,
        default: 'var(--green)'
    }
})
const width = computed(()=> {
    const w = (Number(props.progress) * 100).toFixed(0)
    if(w > 100) return '100%'
    else return w + '%'
})
</script>

<style>
.bar {
    min-height: 8px;
    height: 8px;
    max-height: 8px;
    border-radius: 4px;
}
.bar-container {
    position: relative;
    width: 100%;
    background-color: rgb(189, 189, 189);
    margin: 10px 0px;
}
.bar-progress {
    position: absolute;
    left: 0;
    z-index: 2;
    border-radius: 4px 0 0 4px;
}
.marker {
    position: absolute;
    background-color: white;
    z-index: 3;
    top: calc((18px - 8px) * -0.5);
    height: 18px;
    width: 3px;
    border-radius: 1px;
    box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 1px 1px;
}
</style>