const AccountRoot = [
    ['lsfDefaultRipple',    0x00800000],
    ['lsfDepositAuth',      0x01000000],
    ['lsfDisableMaster',    0x00100000],
    ['lsfDisallowXRP',      0x00080000],
    ['lsfGlobalFreeze',     0x00400000],
    ['lsfNoFreeze',         0x00200000],
    ['lsfPasswordSpent',    0x00010000],
    ['lsfRequireAuth',      0x00040000],
    ['lsfRequireDestTag',   0x00020000]
]

const AccountSet = [
    //tf = transaction flag
    ['tfRequireDestTag',    0x00010000], // SetFlag asfRequireDest
    ['tfOptionalDestTag',   0x00020000], // ClearFlag asfRequireDest
    ['tfRequireAuth',       0x00040000], // SetFlag asfRequireAuth
    ['tfOptionalAuth',      0x00080000], // Clear asfRequireAuth
    ['tfDisallowXRP',       0x00100000], // SetFlag asfDisallowXRP
    ['tfAllowXRP',          0x00200000], // clearflag asfDisallowXRP

    // asf = account set flag
    ['asfAccountTxnID',     5],
    ['asfDefaultRipple',    8],
    ['asfDepositAuth',      9],
    ['asfDisableMaster',    4],
    ['asfDisallowXRP',      3],
    ['asfGlobalFreeze',     7],
    ['asfNoFreeze',         6],
    ['asfRequireAuth',      2],
    ['asfRequireDest',      1]
]

const OfferCreate = [
    ['tfPassive',           0x00010000],
    ['tfImmediateOrCancel', 0x00020000],
    ['tfFillOrKill',        0x00040000],
    ['tfSell',              0x00080000]
]
// const tfPassive	= 0x00010000
// const tfImmediateOrCancel = 0x00020000
// const tfFillOrKill = 0x00040000
// const tfSell = 0x00080000

const Payment = [
    ['tfNoDirectRipple',    0x00010000],
    ['tfPartialPayment',    0x00020000],
    ['tfLimitQuality',      0x00040000]
]

const TrustSet = [
    ['tfSetfAuth',      0x00010000],
    ['tfSetNoRipple',   0x00020000],
    ['tfClearNoRipple',	0x00040000],
    ['tfSetFreeze',	    0x00100000],
    ['tfClearFreeze',	0x00200000]
]

const txFlags = {
    AccountRoot: AccountRoot,
    AccountSet: AccountSet,
    OfferCreate: OfferCreate,
    Payment: Payment,
    TrustSet: TrustSet
}

const checkTxType = type => {
    if(!txFlags.hasOwnProperty(type)) throw new Error('The transaction Type is not in the list of Flags')
}

const getFlagOptions = (type) => {
    checkTxType(type)
    return txFlags[type]
}

const parseFlags = (flags, type) => {
    checkTxType(type)
    const array = []
    txFlags[type].forEach(item => {
        // item[1] == the flag to check for
        // obj[item[0]] = (flags & item[1]) == item[1]
        if((flags & item[1]) == item[1]) array.push(item[0])
    })
    return array
}

const setFlags = (flagArray, type) => {
    checkTxType(type)

    let Flags
    flagArray.forEach(flag => {
        for(const item of txFlags[type]) {
            if(flag === item[0] || flag === item[1]) {
                Flags = Flags | item[1]
                break
            }
        }
    })
    return Flags
}

export default {
    getFlagOptions,
    parseFlags,
    setFlags
}


// if (this.method === 'market') {
//     OfferCreate['Flags'] = tfFillOrKill | tfSell
// } else {
//     switch(this.type) {
//         case 'GoodTillCancel':
//             OfferCreate['Flags'] = tfSell
//             break
//         case 'ImmediateOrCancel':
//             OfferCreate['Flags'] = tfImmediateOrCancel | tfSell
//             break
//         case 'FillOrKill':
//             OfferCreate['Flags'] = tfFillOrKill | tfSell
//             break
//         case 'GoodTillDate':
//             OfferCreate['Flags'] =  tfSell
//             break
//     }
// }