<template>
    <div class="container">
        <!-- <h1>{{ $t('headers.multi_signature') }}</h1> -->
        <img src="../assets/png/data-encryption.png" style="width: 140px; margin: 0 auto;">
        <div class="body-no-multisig">
            <div class="p-box">
                <h3 style="text-align: center;">{{ $t('setup.config_missing') }}</h3>
                <p>{{ $t('setup.config_missing_body') }}</p>
            </div>
            <div style="margin-top: auto; margin-bottom: 20px;">
                <a class="btn btn-primary" @click="routeSetup()">{{ $t('buttons.setup_signerlist') }}</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        init: Boolean
    },
    computed: {
        hasMultiSignEnabled() {
            return this.$store.getters.hasMultiSignEnabled
        }
    },
    methods: {
        routeSetup() {
            return this.$router.push('/setup')
        }
    }
}
</script>

<style scoped>
.body-no-multisig {
    padding: 10px;
    flex: 1;
    display: flex;
    flex-direction: column;
}
.p-box {
    border: 1px solid var(--grey);
    border-radius: 10px;
    background-color: var(--grey6);
    padding: 20px;
    margin-top: auto;
}
.p-box h3 {
    color: var(--blue);
    font-size: 1.2rem;
}
.p-box p {
    text-align: center;
}
.btn {
    margin: 10px 0;
}
</style>
