<template>
    <div class="wrapper-tooltip" :class="{ expanded: showTooltip }" @click="toggle()" ref="tooltip">
        <slot></slot>
        <div class="icon-wrapper-tooltip">
            <slot name="icon">
                <img src="../assets/svg/exclemationmark.bubble.fill.svg" class="tooltip-image invert-color">
            </slot>
        </div>
        <template v-if="showTooltip">
            <div class="backdrop" style="z-index: 5;"></div>
            <div class="pointer-tooltip" style="top: 18px; left: calc(50% - 10px); z-index: 7;"></div>
            <div class="tooltip" :style="{ left: `${tooltipPosition.left}px`, top: `${tooltipPosition.top + 10}px`, right: `${tooltipPosition.right}px` }">
                <!-- <div class="pointer-tooltip"></div> -->
                <slot name="info">
                    {{ info }}
                    <!-- {{ tooltipPosition }} -->
                </slot>
            </div>
        </template>
    </div>
</template>

<script setup>
import { ref, computed, watchEffect } from 'vue'
defineProps(['info'])
const showTooltip = ref(false)
const tooltip = ref(null)

const toggle = () => {
    showTooltip.value = !showTooltip.value
}

const tooltipPosition = ref({ top: 0, left: 0 });
watchEffect(() => {
    if (showTooltip.value && tooltip.value) {
        const rect = tooltip.value.getBoundingClientRect()
        console.log('Tooltip position:', rect)

        tooltipPosition.value = {
          top: rect.top + rect.height,
          left: rect.left
        }

        const width = 200
        const halfWidth = width / 2
        const viewportWidth = window.innerWidth

        const leftBounds = rect.left - halfWidth
        if(leftBounds < 10) {
            // check if out of bounds
            tooltipPosition.value.left = 10
        } else tooltipPosition.value.left = rect.left - (200 / 2) + 10

        const rightBounds = rect.right + halfWidth
        if(rightBounds > viewportWidth - 10) {
            tooltipPosition.value.right = 10
        }
    }
})

const position = computed(() => {
    if(tooltip.value) {
        const left = tooltip.value.getBoundingClientRect().left
        const top = tooltip.value.getBoundingClientRect().top
        // todo Check if element is out of bounds
        const right = tooltip.value.getBoundingClientRect().right
        const pos = {
            top,
            right,
            left
        }
        console.log(pos)
        return pos
    }
    return tooltip
})

const movedPosition = computed(() => {
    const width = 200
    const padding = 10
    const move = {
        left: position.value.left < 0
    }
    const obj = {
        left: move.left ? position.value.left - padding : width / -2
    }
    return obj
})
</script>

<style>
.wrapper-tooltip {
    display: flex;
    flex-direction: row;
    position: relative;
    cursor: pointer;
}
.wrapper-tooltip.expanded * {
    z-index: 6;
}
.icon-wrapper-tooltip > img {
    position: absolute;
    top: -6px;
    right: -12px;
    max-height: 12px;
    width: 12px;
}
.tooltip-image {
    width: 12px;
}
.backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgb(var(--var-backdrop), 0.3);
}
.tooltip {
    position: fixed;
    /* top: calc(1rem + 10px); */
    background-color: var(--grey4);
    max-width: 200px;
    padding: 10px 15px;
    border-radius: 10px;

    font-size: 0.8rem;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.pointer-tooltip {
    position: absolute;
    /* top: -10px; */
    /* left: calc((100% - 10px) / 2); */
    width: 0; 
    height: 0; 
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid var(--grey4);
}
</style>