
import { createStore } from 'vuex'

import settings from './modules/settings'
import account from './modules/account'
import multisign from './modules/multisign'

export default createStore({
    modules: {
        settings,
        account,
        multisign
    }
})
