<template>
    <div id="home-screen">
        <div class="multi-sign-header">
            <a>
                <img src="../assets/svg/signature.svg" style="height: 20px;" class="invert-color">
            </a>
            <AccountHeader/>
            <a @click="$router.push('/about')" class="btn">
                <!-- <img src="../assets/svg/gearshape.2.fill.svg" style="height: 20px;" class="invert-color"> -->
                <img src="../assets/png/help-web-button.png" style="height: 20px;" class="invert-color">
            </a>
        </div>
        <div class="signer-list-settings-container">
            <div>
                <ToolTip :info="ticketsAvailable > 1 ? $t('tooltips.tickets') : $t('tooltips.no_tickets')">
                    <h6>{{ $t('headers.tickets') }}</h6>
                    <template #icon v-if="ticketsAvailable <= 1">
                        <img src="../assets/svg/exclemationmark.circle.fill.svg">
                    </template>
                </ToolTip>
                <h1>{{ ticketsAvailable || 0 }}/{{ tickets }}</h1>
            </div>
            <div>
                <a class="btn" @click="actionButtonRoute()" style="display: flex; flex-direction: column; align-items: center; justify-content: center; cursor: pointer; width: fit-content; padding: 5px 20px; padding-bottom: 0; border: 1px solid var(--grey2); background-color: rgba(255, 255, 255, 0.05);">
                    <img v-if="ticketsAvailable <= 1" src="../assets/png/ticket.png" style="height: 30px;">
                    <img v-else src="../assets/png/tools.png" style="height: 30px;">
                    {{ ticketsAvailable <= 1 ? 'TicketCreate' : $t('buttons.create_transaction') }}
                </a>
            </div>
            <div>
                <ToolTip :info="$t('tooltips.quorum')">
                    <h6>{{ $t('headers.quorum') }}</h6>
                </ToolTip>
                <h1>{{ signerList?.SignerQuorum }}</h1>
            </div>
            <div>
                <h6>{{ $t('headers.total_weight') }}</h6>
                <h1>{{ totalWeight }}</h1>
            </div>
        </div>
        <hr>
        <!-- List of TX to be signed -->
        <QueuedTxList />
    </div>
</template>

<script>
import { currencyFormat } from '../plugins/number-format'
import QueuedTxList from './QueuedTxList.vue'
import AccountHeader from './AccountHeader.vue'

export default {
    components: { QueuedTxList, AccountHeader },
    computed: {
        accountInfo() {
            return this.$store.getters.getAccountInfo
        },
        tickets() {
            return this.$store.getters.getAvailableTickets.length
        },
        ticketsAvailable() {
            const unique = this.$store.getters.getUnusedTickets
            return unique.length
        },
        isDevMode() {
            return process?.env?.VUE_APP_DEV_MODE?.toLowerCase() === 'true'
        },
        signerList() {
            return this.$store.getters.getSignerList
        },
        totalWeight() {
            return this.$store.getters.getSignerWeightTotal
        },
        ticketCreateTx() {
            return this.$store.getters.getQueuedTxs(false).filter(tx => {
                return !tx.hasOwnProperty('submit_result') && tx.txjson.TransactionType === 'TicketCreate'
            })
        }
    },
    methods: {
        currencyFormat,
        actionButtonRoute() {
            if(this.ticketsAvailable <= 1 && this.ticketCreateTx?.length >= 1) this.$router.push({ name: 'ViewTx', params: { comp: 'view', id: this.ticketCreateTx[0]._id}} )
            else this.$router.push('/transaction/compose')
        }
    },
    mounted() {
        // todo
        // this.$store.dispatch('getMultisignAccountInfo')
    }
}
</script>

<style scoped>
#home-screen {
    height: 100%;
    display: flex;
    flex: 2 auto;
    flex-direction: column;
    align-items: center;
}
.multi-sign-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: -webkit-fill-available;
}
.multi-sign-header > a {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.signer-list-settings-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.signer-list-settings-container > div {
    width: 100px;
    flex-basis: calc(50% - 10px);
    margin: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
@media (min-width: 500px) {
    .signer-list-settings-container {
        flex-wrap: nowrap;
        margin: 0 auto;
    }
    .signer-list-settings-container > div {
        flex-basis: none;
    }
}
.signer-list-settings-container > div > *:last-child {
    margin: auto;
    height: 50px;
    max-height: 50px;
}
</style>
