<template>
    <form v-if="view === 'compose'">
        <hr style="margin-bottom: 20px;">
        <div style="display: flex; flex-direction: row; align-items: center; margin-top: 20px;">
            <label style="text-wrap: nowrap; font-size: 1.2rem; margin-right: 30px;">Amount</label>
            <InputAmount v-model="amount" class="number" :class="{ 'input-error': DestinationTag < 0 || DestinationTag > 4294967295 }" />
            <a @click="$emitter.emit('currencySelect', true)" class="btn" style="min-width: 50px; max-width: max-content; height: 42px; padding-left: 15px; border-radius: 0; border-bottom: 2px solid var(--var-border);">
                <span>{{ currencyCodeFormat(currency) }}</span>
                <img src="../../assets/svg/chevron-left.svg" style="padding: 0 10px;transform: rotate(180deg); scale: 0.9;" class="invert-color">
            </a>
        </div>
        <img src="../../assets/svg/arrow.down.svg" style="margin: 40px; height: 25px;" class="invert-color">
        <div class="account-picker" @click="destinationPicker()">
            <div class="account-container">
                <h3>{{ destinationName || 'Destination' }}</h3>
                <code>{{ Destination ? compressString(Destination, 6) : 'Select by clicking...' }}</code>
            </div>
            <label class="label">
                <img src="../../assets/svg/chevron-left.svg" style="transform: rotate(180deg);" class="invert-color">
            </label>
        </div>

        <div style="display: flex; flex-direction: row; align-items: center; gap: 40px; margin-top: 30px;">
            <label style="text-wrap: nowrap; font-size: 1.2rem;">Destination Tag</label>
            <input type="number" inputmode="numeric" v-model="DestinationTag" min="0" max="4294967295" class="number" :class="{ 'input-error': DestinationTag < 0 || DestinationTag > 4294967295 }" style="font-weight: bold; max-width: 170px; margin-left: auto;">
        </div>
        
        <!-- MEMO component -->
        <SpinnerButton class="btn btn-success push-top" @click="sendTx(txjson)">{{ $t('buttons.submit') }}</SpinnerButton>

        <SelectCurrency @currencyChange="currencyChange"/>
    </form>

    <div v-else-if="view === 'view'" style="padding: 20px 0; display: flex; flex-direction: column; align-items: center; justify-content: center;">
        <h2>{{ quantityFormat(transaction.txjson.Amount.value || transaction.txjson.Amount, transaction.txjson.Amount.currency || true) }} {{ currencyCodeFormat(transaction.txjson.Amount.currency || $store.getters.getNativeCurrency) }}</h2>
        <img src="../../assets/svg/arrow.down.svg" style="margin: 15px;height: 25px;" class="invert-color">
        <div style="display: flex; flex-direction: column; align-items: center; gap: 5px;">
            <code v-if="typeof transaction.txjson.Destination === 'string'">{{ compressString(transaction.txjson.Destination, 12) }}</code>
            <span v-if="transaction.txjson.DestinationTag" class="number"><code>Tag: </code>{{ transaction.txjson.DestinationTag }}</span>
        </div>
    </div>

    <div v-else-if="view === 'listItem'" style="display: flex; flex-direction: row; align-items: center; gap: 15px; padding-top: 10px;">
        <span style="flex: 1; text-align: right;">{{ quantityFormat(transaction.txjson.Amount.value || transaction.txjson.Amount, transaction.txjson.Amount.currency || true) }} {{ currencyCodeFormat(transaction.txjson.Amount.currency || $store.getters.getNativeCurrency) }}</span>
        <img src="../../assets/svg/arrow.down.svg" style="height: 15px; transform: rotate(-90deg);" class="invert-color">
        <code style="flex: 1;" v-if="typeof transaction.txjson.Destination === 'string'">{{ compressString(transaction.txjson.Destination, 5) }}</code>
    </div>
</template>

<script>
// https://xrpl.org/payment.html#payment

import xapp from '../../plugins/xapp'
import mixin from '../../mixins/multiSign'

import InputAmount from '../InputAmount.vue'
import SelectCurrency from '../SelectCurrency.vue'

import { currencyCodeFormat, quantityFormat, compressString } from '../../plugins/number-format'

export default {
  components: { InputAmount, SelectCurrency },
    props: {
        view: {
            validator(value) {
                return ['compose', 'view', 'listItem'].includes(value)
            }
        },
        transaction: Object
    },
    mixins: [mixin],
    data() {
        return {
            TransactionType: 'Payment',
            Destination: null,
            destinationName: null,
            DestinationTag: null,
            amount: null,
            currency: this.$store.getters.getNativeCurrency,
            issuer: null,
            Flags: 0
        }
    },
    computed: {
        txjson() {
            if(!this.NextTicketSequence) return null

            const tx = {
                TransactionType: this.TransactionType,
                Account: this.$store.getters.getAccount,
                Destination: this.Destination,
                Amount: this.currency === this.$store.getters.getNativeCurrency ? Math.trunc(parseFloat(this.amount) * 1_000_000).toString() : { currency: this.currency, value: this.amount, issuer: this.issuer },
                Flags: this.Flags,
                TicketSequence: this.NextTicketSequence,
                Sequence: 0
            }

            if(this.DestinationTag > 0 && this.DestinationTag <= 4294967295 && this.DestinationTag) tx['DestinationTag'] = this.DestinationTag

            return tx
        }
    },
    methods: {
        currencyCodeFormat,
        quantityFormat,
        compressString,
        currencyChange(value) {
            console.log(value)
            console.log('Currency change')
            this.currency = value.currency
            this.issuer = value.issuer
        },
        async destinationPicker() {
            try {
                const result = await xapp.destinationSelect()
                if(!result.destination || result.destination === null) return null

                this.destinationName = result.destination.name
                this.Destination = result.destination.address
                this.DestinationTag = result.destination.tag
            } catch (e) {
                console.log(e)
                this.$emitter.emit('modal', {
                    type: 'error',
                    title: this.$t('xapp.error.modal_title'),
                    text: 'Error with the Account Destination Selector',
                    buttonText: this.$t('xapp.button.close')
                })
            }
        }
    }
}
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input.number {
    color: var(--var-txt-color);
    background-color: var(--var-bg-color);
    border: 0;
    width: calc(100% - 20px);
    text-align: end;
    padding: 5px 10px;
    font-size: 1.5rem;
    border-radius: 0px;
    border-bottom: 2px solid var(--var-border);
}
input.number:focus {
    outline: 0;
}
input.number:focus-within {
    border-color: var(--var-primary);
}
</style>