<template>
    <div v-if="!tx.submit_result" class="progress-bars-container">
        <h4>Yeas</h4>
        <ProgressBar :progress="calculateRate(getSignerWeightCombined(tx.signed_txs))" :threshold="calculateRate(SignerQuorum)"/>
        <h4 style="justify-content: right;">{{ calculateProgress(getSignerWeightCombined(tx.signed_txs)) }}%</h4>
        <h4 v-if="tx?.reject_tx?.signed_txs">Nays</h4>
        <ProgressBar v-if="tx?.reject_tx?.signed_txs" :progress="calculateRate(getSignerWeightCombined(tx.reject_tx.signed_txs))" :threshold="calculateRate(SignerQuorum)" bar-color="var(--red)" bar-color-threshold="var(--red)"/>
        <h4 v-if="tx?.reject_tx?.signed_txs" style="justify-content: right;">{{ calculateProgress(getSignerWeightCombined(tx.reject_tx.signed_txs)) }}%</h4>
    </div>
    <div class="action-row" v-if="options?.showSubmit || options?.showReject">
        <SpinnerButton class="btn btn-cancel" v-if="options?.showReject" @click="reject()">Reject</SpinnerButton>
        <SpinnerButton class="btn btn-success" v-if="options?.showSubmit" @click="submit()">Submit</SpinnerButton>
    </div>
</template>

<script setup>
import ProgressBar from './ProgressBar.vue'
import multiSign from '../plugins/multi-sign'

import { ref, computed, inject } from 'vue'
const store = inject('store')

const props = defineProps(['tx', 'options'])

const SignerQuorum = ref(store.getters.getAccountInfo?.signer_lists?.[0]?.SignerQuorum)

const calculateProgress = (weight) => {
    return Math.floor(Number(weight / SignerQuorum.value) * 100) || 0
}

const calculateRate = (weight) => {
    const totalWeight = store.getters.getSignerWeightTotal
    if(!weight || !totalWeight) return 0
    return Number(Number(weight) / Number(totalWeight)).toFixed(3)
}

const getSignerWeightCombined = (txs) => {
    const signersArray = txs.map(item => item.signer)
    return store.getters.getSignerWeightCombined(signersArray)
}

const submit = async () => {
    try {
        const res = await multiSign.submitMultiSignedTx(props.tx._id)
        store.dispatch('updateQueuedTx', res.document)
        const txResult = res?.result
        if(txResult?.engine_result && txResult?.engine_result !== 'tesSUCCESS') {
            throw txResult?.engine_result_message
        }
    } catch(e) {
        console.log(e)
        alert('Problem with submitting')
    }
}

const ticketDeleteTransaction = computed(() => {
    if(props.tx.txjson.TransactionType === 'TicketDelete') return null
    return props.tx.reject_tx ? props.tx.reject_tx : null
})

const reject = async () => {
    try {
        const res = await multiSign.submitMultiSignedTx(ticketDeleteTransaction.value._id)
        store.dispatch('updateQueuedTx', res.document)
        const txResult = res?.result
        if(txResult?.engine_result && txResult?.engine_result !== 'tesSUCCESS') {
            throw txResult?.engine_result_message
        }
    } catch(e) {
        console.log(e)
        alert('Problem with rejecting')
    }
}
</script>

<style scoped>
.progress-bars-container {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 50px 1fr 50px;
}
.progress-bars-container > h4 {
    display: flex;
    align-items: center;
}
.action-row {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin: 10px 0;
}
</style>
