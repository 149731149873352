<template>
    <div class="signed-tx-list">
        <ProgressTx :tx="transaction" :options="{ showSubmit, showReject }"/>
        <ul class="signer-list">
            <li v-for="signer in signerList.SignerEntries" :key="signer.SignerEntry.Account" class="signer-item">
                <div class="vote-account-header">
                    <h4 v-if="hasSigned(signer.SignerEntry.Account)">
                        <img src="../assets/svg/plus.circle.fill.svg">
                        Signed {{ signer.SignerEntry.Account === transaction.initiator ? '& Initiated' : null }}
                    </h4>
                    <h4 v-else-if="rejectedVotes.includes(signer.SignerEntry.Account) || transactionSubmitted">
                        <template v-if="rejectedVotes.includes(signer.SignerEntry.Account)">
                            <img src="../assets/svg/xmark.circle.fill.svg">
                            Rejected
                        </template>
                        <template v-else>
                            <img src="../assets/svg/circle.dashed.svg" class="invert-color">
                            Abstained
                        </template>
                    </h4>
                    <h4 v-else>
                        <img src="../assets/svg/hourglass.circle.fill.svg">
                        Waiting for vote
                    </h4>
                    <h2>{{ signer.SignerEntry.SignerWeight }}x</h2>
                </div>
                <div class="signer-item-account">
                    <h4>Signer</h4>
                    <code >{{ compressString(signer.SignerEntry.Account, 12) }}</code>
                </div>
                <div class="btn-row" v-if="!transactionSubmitted">
                    <template v-if="transaction.txjson.TransactionType !== 'TicketDelete'">
                        <SpinnerButton v-if="!rejectedVotes.includes(signer.SignerEntry.Account)" class="btn btn-cancel" @click.prevent="removeSignature(signer.SignerEntry.Account)" :key="signer.SignerEntry.Account + '_R'">Reject</SpinnerButton>
                        <div v-else class="sign-result-label"><img src="../assets/svg/xmark.circle.fill.svg"><span>Rejected</span></div>
                    </template>
                    <SpinnerButton v-if="!hasSigned(signer.SignerEntry.Account)" class="btn btn-success" @click.prevent="addSignedTxToList(signer.SignerEntry.Account, transaction)" :key="signer.SignerEntry.Account + '_A'">Approve</SpinnerButton>
                    <div v-else class="sign-result-label"><img src="../assets/svg/plus.circle.fill.svg"><span>Signed</span></div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import xapp from '../plugins/xapp'
import multiSign from '../plugins/multi-sign'
import ProgressTx from '../components/ProgressTx.vue'
import { compressString } from '../plugins/number-format'

import mixin from '../mixins/multiSign'

export default {
    components: { ProgressTx },
    props: {
        transaction: Object
    },
    mixins: [mixin],
    methods: {
        hasSigned(account) {
            if(typeof this.transaction?.reject_tx?.submit_result === 'string' && this.rejectedVotes.includes(account))
                return false
            for(const signedTX of this.transaction.signed_txs) {
                if(signedTX.signer === account) return true
            }
        },
        async addSignedTxToList(signer, transaction) {
            const txjson = { ...transaction.txjson }
            txjson.TransactionType === 'TicketDelete' ? txjson.TransactionType = 'AccountSet' : null
            console.log(txjson)
            let uuid
            try {
                uuid = await xapp.signPayload({
                    txjson: txjson,
                    options: {
                        multisign: true,
                        submit: false,
                        signers: signer
                    }
                }, true)

            } catch(e) {
                console.log(e)
                if(e === 'DECLINED') return
                if(e) alert(e)
                else alert(this.$t('signing.error'))
                return
            }

            try {
                const txId = transaction._id
                const signedTxResult = await multiSign.addSignedTxToList(txId, uuid)

                console.log('the Goods:')
                console.log(signedTxResult)

                this.$store.dispatch('setQueuedTxs')
            } catch(e) {
                console.log(e)
                if(e) alert(e)
                else alert('Unknown issue with adding the signature to the list')
            }
        },
        async removeSignature(signer) {
            const deleteTx = this.ticketDeleteTransaction
            if(deleteTx) return this.addSignedTxToList(signer, deleteTx)

            const TicketSequence = this.transaction.txjson.TicketSequence
            if(!TicketSequence || TicketSequence <= 0) throw new Error('Removing Signature requires a TicketSequence Number')
            const txjson = {
                Account: this.transaction.txjson.Account,
                TransactionType: 'AccountSet',
                TicketSequence: TicketSequence
            }
            return this.sendTx(txjson, false)
        },
        calculateRate(weight) {
            if(!weight || !this.totalWeight) return 0
            return Number(Number(weight) / Number(this.totalWeight)).toFixed(3)
        },
        calculateSignerProgressWeight(tx) {
            if(!tx) return null

            const signersArray = tx.signed_txs.map(item => item.signer)
            return this.$store.getters.getSignerWeightCombined(signersArray)
        },
        compressString
    },
    computed: {
        signerList() {
            return this.$store.getters.getSignerList
        },
        totalWeight() {
            return this.$store.getters.getSignerWeightTotal
        },
        ticketDeleteTransaction() {
            if(this.transaction.txjson.TransactionType === 'TicketDelete') return null
            return this.transaction.reject_tx ? this.transaction.reject_tx : null
        },
        rejectedVotes() {
            if(!this.ticketDeleteTransaction) return []

            const rejectTx = this.ticketDeleteTransaction
            return rejectTx.signed_txs.map(signs => signs.signer)
        },
        transactionSubmitted() {
            return typeof this.transaction?.submit_result === 'string' || typeof this.transaction?.reject_tx?.submit_result === 'string'
        },
        showSubmit() {
            if(this.transactionSubmitted) return false
            return this.calculateSignerProgressWeight(this.transaction) >= this.signerList.SignerQuorum && !this.transaction.tx_hash
        },
        showReject() {
            if(this.transactionSubmitted) return false
            return this.calculateSignerProgressWeight(this.ticketDeleteTransaction) >= this.signerList.SignerQuorum && !this.transaction.tx_hash
        }
    }
}
</script>

<style scoped>
.signed-tx-list {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    min-height: 0;
}
.progress-header-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin: 10px 0;
}
ul.signer-list {
    list-style-type: none;
    min-height: 0;
    margin: 0;
    padding: 0;
    padding-top: 10px;
    max-width: 400px;
    gap: 10px;
    display: flex;
    flex-direction: column;
}
li.signer-item {
    display: flex;
    flex-direction: column;
    border: 1px;
    border-radius: 5px;
    background-color: var(--grey5);
    padding: 10px;
}
.signer-item-account {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    padding: 10px 0;
}
.vote-account-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--var-border);
}
.vote-account-header > h4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}
.btn-row {
    border-top: 1px solid var(--var-border);
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
}
/* .btn-row > .btn {
    background-color: transparent;
    border: 2px solid;
} */
/* .btn-row > .btn.btn-success {
    border-color: var(--green);
    color: var(--green);
}
.btn-row > .btn.btn-cancel {
    border-color: var(--red);
    color: var(--red);
} */
.sign-result-label {
    width: 100%;
    display: flex;
    gap: 10px;
    text-wrap: nowrap;
    justify-content: center;
}
.sign-result-label > span {
    font-size: 1.1rem;
    font-weight: bold;
}
</style>