import { createApp } from 'vue'
import App from './App.vue'
import store from './store'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowLeft, faArrowRight, faArrowDown, faLevelDownAlt,faSignInAlt, faTimesCircle, faExclamationCircle, faArrowUp, faTimes, faCaretRight, faCaretUp, faCaretDown, faChevronLeft, faChevronRight, faChevronDown, faCheckCircle, faCalendar, faHistory, faChartLine, faTrashAlt, faShareAlt, faList } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import Notifications from '@kyvg/vue3-notification'

library.add(faArrowLeft, faArrowRight, faArrowUp, faArrowDown, faLevelDownAlt, faSignInAlt, faTimesCircle, faTimes, faExclamationCircle, faCaretRight, faCaretUp, faCaretDown, faChevronLeft, faChevronRight, faChevronDown, faCheckCircle, faCalendar, faHistory, faChartLine, faTrashAlt, faShareAlt, faList)
import xapp from './plugins/xapp'

try {
    xapp.setOtt()
} catch(e) {
    alert('Error Setting OTT')
}

const app = createApp(App).use(router)

import { createI18n } from 'vue-i18n'

import { languages, defaultLocale } from './locale/index.js'
const messages = Object.assign(languages)

const i18n = createI18n({
    locale: defaultLocale,
    fallbackLocale: 'en',
    messages
})

import mitt from 'mitt'
const emitter = mitt()
app.config.globalProperties.$emitter = emitter

import SpinnerButton from './components/SpinnerButton.vue'
import ToolTip from './components/ToolTip.vue'
import router from './router'

app.use(store)
app.use(Notifications)
app.use(i18n)
app.component('fa', FontAwesomeIcon)
app.component('SpinnerButton', SpinnerButton)
app.component('ToolTip', ToolTip)

app.mount('#app')
