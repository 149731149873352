<template>
    <div class="about">
        <nav>
            <a @click="$router.go(-1)" class="btn">
                <fa :icon="['fas', 'chevron-left']"/>
            </a>
            <h1>About</h1>
            <a style="width: 40px;"></a>
        </nav>
        <div class="container">
            <img src="../assets/png/data-encryption.png" style="width: 180px; margin: 0 auto;">
            <h3>Multi Signature</h3>
            <p class="p-box">
                Multi-signature, often abbreviated as multi-sig, is a security feature that requires multiple private keys to authorize a transaction on the XRPL. This innovative approach enhances the security of digital asset transactions by introducing a collaborative approval process.
                <br>
                <br>
                In a traditional single-signature transaction, only one private key is required to initiate and complete a transfer of assets. However, with multi-signature, the involvement of multiple private keys adds an extra layer of security. This means that even if one private key is compromised, the transaction cannot proceed without the required number of additional signatures.
            </p>
        </div>
    </div>
</template>

<style scoped>
nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
nav > a {
    max-width: 40px;
}
h1 {
  text-align: center;
}
.p-box {
  padding: 20px;
}
</style>
