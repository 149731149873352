<template>
    <div class="tx-list">

        <template v-if="isDevMode">
            <h3 style="color: red">DEV MODE: Available ticket count {{ getTickets.length }}</h3>
            <span style="color: red">{{ getTickets }}</span>
        </template>

        <div class="tabs-row">
            <label for="tab1" :class="{ 'checked': activeTab === '1' }">
                <input type="radio" value="1" name="tabs" id="tab1" v-model="activeTab"/>
                <span style="position: relative;">{{ $t('headers.requests') }}</span>
                <!-- {{ openRequest.length > 99 ? '99+' : openRequest.length }}x -->
                <!-- <label v-show="openRequest.length" class="badge">{{ openRequest.length > 99 ? '99+' : openRequest.length }}</label> -->
            </label>

            <label for="tab2" :class="{ 'checked': activeTab === '2' }">
                <input type="radio" value="2" name="tabs" id="tab2" v-model="activeTab"/>
                <span>{{ $t('headers.history') }}</span>
            </label>

            <label for="tab3" :class="{ 'checked': activeTab === '3' }">
                <input type="radio" value="3" name="tabs" id="tab3" v-model="activeTab"/>
                <span>Invalid</span>
            </label>
        </div>

        <ul v-if="queuedTxList.length > 0 && accInfo" class="transaction-list">
            <li v-for="tx in queuedTxList">
                <TransactionView comp="listItem" :txId="tx._id"/>
            </li>
        </ul>
        <div v-else class="container" style="justify-content: center; color: grey;">
            <img src="../assets/png/to-do-list.png" style="width: 60px; filter: invert(0.6);"/>
            <h3 v-if="getTickets.length < 1 && activeTab === '1'">{{ $t('headers.no_tickets') }}</h3>
            <h3 v-else>{{ $t('headers.no_transactions') }}</h3>
        </div>
    </div>
</template>

<script>
import TransactionView from '@/views/Transaction.vue'

export default {
    components: { TransactionView },
    data() {
        return {
            loading: false,
            activeTab: '1'
        }
    },
    computed: {
        getTickets() {
            return this.$store.getters.getAvailableTickets
        },
        openRequest() {
            return this.$store.getters.getQueuedTxs(false)
        },
        queuedTxList() {
            let txs = []
            switch(this.activeTab) {
                case '1':
                    txs = this.openRequest
                    break
                case '2':
                    txs = this.$store.getters.getHistoryTransactions
                    // .filter(tx => tx.hasOwnProperty('submit_result'))
                    break
                case '3':
                    txs = this.$store.getters.getQueuedTxs(true)
            }
            return txs.length > 0 ? txs.sort((a, b) => (b.txjson.TicketSequence || b.txjson.Sequence) - (a.txjson.TicketSequence || a.txjson.Sequence)) : []
        },
        SignerQuorum() {
            // return this.accInfo?.signer_lists[0]?.SignerQuorum
        },
        accInfo() {
            return this.$store.getters.getAccountInfo
        },
        isDevMode() {
            return process?.env?.VUE_APP_DEV_MODE?.toLowerCase() === 'true'
        }
    },
    methods: {
        getSignerWeightCombined(txs) {
            const signersArray = txs.map(item => item.signer)
            return this.$store.getters.getSignerWeightCombined(signersArray)
        },
        getTxWeight() {
            this.$store.getters.getSignerWeightCombined()
        },
        async setQueuedTxs() {
            this.loading = true
            this.$store.dispatch('setQueuedTxs')
            this.loading = false
        },
        // accountInfo.signer_lists[0].SignerEntries
        // accountInfo.signer_lists[0].SignerQuorum
    },
    mounted() {
        this.$store.dispatch('setQueuedTxs')
    }
}
</script>

<style scoped>
.tx-list {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
    min-height: 0;
}
ul.transaction-list {
    flex: 1 1 auto;
    overflow-y: auto;
    min-height: 0;
    margin: 0;
    padding: 0 20px;
    list-style-type: none
}
ul.transaction-list li {
    padding: 10px;
    background-color: var(--grey4);
    border-radius: 5px;
    margin: 20px 0;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.badge {
    position: absolute;
    top: -10px;
    left: calc(100% - 10px);
    background-color: var(--red);
    color: white;
    border-radius: 10px;
    padding: 0 5px;
    font-size: 0.8rem;
    font-weight: bold;
    height: 20px;
    min-width: 10px;
    text-align: center;
    scale: 0.9;
}
</style>