import xrpl from '../../plugins/ws-client'
import _merge from 'lodash/merge'

const state = {
    address: null,
    accountInfo: {},
    accountObjects: [],
    accountTransactions: []
}

const getters = {
    hasAccountErrors: state => {
        if(typeof state.accountInfo === 'string') return true
        else return false
    },
    getAccount: state => {
        return state.address
    },
    getAccountInfo: state => {
        return state.accountInfo
    },
    hasMultiSignEnabled: state => {
        return state.accountInfo?.signer_lists?.length > 0
    },
    getSignerList: (state, getters) => {
        if(!getters.hasMultiSignEnabled) return {}
        return {
            SignerQuorum: state.accountInfo?.signer_lists[0]?.SignerQuorum,
            SignerEntries: state.accountInfo?.signer_lists[0]?.SignerEntries
        }
    },
    getSignerListArray: state => {
        const list = state.accountInfo?.signer_lists[0]?.SignerEntries
        
        const array = list.map(signer => {
            return signer.SignerEntry.Account
        })
        
        return array
    },
    getAvailableTickets: state => {
        return state.accountObjects
            .filter(item => item.LedgerEntryType === 'Ticket')
            .map(item => item.TicketSequence)
            .sort()
    },
    getUnusedTickets: (state, getters, rootState, rootGetters) => {
        const all = getters.getAvailableTickets
        const used = rootGetters.usedTicketSequences
        const unique = all.filter(seq => used.indexOf(seq) == -1)
        return unique
    },
    getTicketHashBySequence: state => seq => {
        if(isNaN(seq)) throw new Error('No TicketSequence')
        for(const object of state.accountObjects) {
            if(object.LedgerEntryType === 'Ticket' && object.TicketSequence === seq) {
                return object.index
            }
        }
        return null
    },
    getAccountObjects: state => {
        return state.accountObjects
    },
    getAccountLines: state => {
        // todo 
        let array = []
        for(const object of state.accountObjects) {
            if(object.LedgerEntryType !== 'RippleState') continue

            let issuerRipple
            let accountRipple
            if( Math.sign(Number(object.Balance?.value)) > 0 ) {
                issuerRipple = object.HighLimit.issuer
                accountRipple = object.LowLimit.issuer
            } else {
                issuerRipple = object.LowLimit.issuer
                accountRipple = object.HighLimit.issuer
            }

            array.push({
                currency: object.Balance.currency,
                issuer: issuerRipple,
                value: Math.abs(object.Balance.value)
            })
        }

        return array
    },
    getAccountFunds: state => (currency, issuer) => {
        if(state.accountInfo === null || typeof state.accountInfo === undefined) return null
        if(state.accountObjects === null || typeof state.accountObjects === undefined) return null

        if(currency === 'XRP') {
            if(typeof state.accountInfo.Balance === undefined) return null
            const accountReserve = 10000000
            const reserved = state.accountObjects.length * 2000000
            const balance = (state.accountInfo.Balance - accountReserve - reserved)
            return balance
        } else {
            if(!Array.isArray(state.accountObjects)) return null

            for(let object of state.accountObjects) {
                if(object.LedgerEntryType === 'RippleState') {
                    if(object.LowLimit.currency === currency && object.LowLimit.issuer === issuer) {
                        if(object.HighLimit.issuer === state.address) {
                            return Math.abs(object.Balance.value)
                        }
                    } else if(object.HighLimit.currency === currency && object.HighLimit.issuer === issuer) {
                        if(object.LowLimit.issuer === state.address) {
                            return Math.abs(object.Balance.value)
                        }
                    }
                }
                continue
            }
            return null
        }
    },
    getSignerWeight: state => (signerAccount) => {
        for(const obj of state.accountInfo.signer_lists[0].SignerEntries) {
            if(obj.SignerEntry.Account === signerAccount) return obj.SignerEntry.SignerWeight
        }
        return null
    },
    getSignerWeightTotal(state) {
        let weight = 0
        if(!Array.isArray(state.accountInfo.signer_lists?.[0]?.SignerEntries)) return null

        for(const obj of state.accountInfo.signer_lists[0].SignerEntries) {
            weight = weight + Number(obj.SignerEntry.SignerWeight)
        }
        return weight
    },
    // getSignedTxList: state => () => {
    //     return null
    // },
    getSignerWeightCombined: state => (array) => {
        let weight = 0
        if(!Array.isArray(state.accountInfo.signer_lists?.[0]?.SignerEntries)) return null

        for(const obj of state.accountInfo.signer_lists[0].SignerEntries) {

            for(const signer of array) {
                if(obj.SignerEntry.Account === signer) {
                    weight = weight + Number(obj.SignerEntry.SignerWeight)
                    break
                }
            }

        }
        return weight
    }
}

const actions = {
    resetData: (context) => {
        context.commit('setAccountObjects', [])
        context.commit('setAccountTransactions', [])
    },
    setAccount: async (context, account) => {
        if(account !== context.state.address) context.dispatch('resetData')
        context.commit('setAccount', account)
        try {
            await context.dispatch('setAccountInfo')
            await Promise.all([context.dispatch('setAccountObjects'), context.dispatch('setAccountTransactions')])
        } catch(e) {
            console.log('Error with account info: ' + e)
        }

        return
    },
    setAccountInfo: async (context) => {
        return new Promise( async (resolve, reject) => {
            const res = await xrpl.send({
                command: 'account_info',
                account: context.state.address,
                signer_lists: true
            })
            if(res.status === 'error') {
                context.commit('setAccountInfo', res.error)
                reject(res.error)
            } else {
                context.commit('setAccountInfo', res.account_data)
                resolve(res.account_data)
            }
        })
    },
    setAccountObjects: async (context, marker) => {
        if(context.getters.hasAccountErrors) {
            return context.commit('setAccountObjects', [])
        }
        
        const res = await xrpl.send({
            command: 'account_objects',
            account: context.state.address,
            marker: marker
        })

        const objects = res.account_objects
        if(marker) {
            context.commit('mergeAccountObjects', objects)
        } else {
            context.commit('setAccountObjects', objects)
        }

        if(Object.hasOwn(res, 'marker')) context.dispatch('setAccountObjects', res.marker)
        return objects
    },
    setAccountTransactions: async (context) => {
        if(context.getters.hasAccountErrors) {
            context.commit('setAccountTransactions', [])
            return
        }

        const res = await xrpl.send({
            command: 'account_tx',
            account: context.state.address
        })
        const reversedTx = res.transactions.reverse()
        context.commit('setAccountTransactions', reversedTx)
    },
    parseTx: (context, payload) => {
        const tx = payload.transaction
        if(payload.notify) console.log(tx)
    },
    changeBalance: (context, balancechanges) => {
        console.log('Balance changes:')
        console.log(balancechanges)
    },
    onNodeChange: (context, node) => {
        if(!node.hasOwnProperty('FinalFields')) return console.warn('Change object without FinalFields Object')
        node.FinalFields.index = node.LedgerIndex
        if(node.LedgerEntryType === 'AccountRoot') {
            console.log('AccountRoot please make changes')
            context.commit('modifyAccountObject', node.FinalFields)
        } else {
            context.commit('modifyObject', node.FinalFields)
        }
    },
    addObjectToAccount: (context, object) => {
        if(!object.hasOwnProperty('NewFields')) return console.warn('Add object without NewFields Object')
        console.log('Add Object to account please...')
        object.NewFields.index = object.LedgerIndex
        object.NewFields.LedgerEntryType = object.LedgerEntryType
        context.commit('addObject', object.NewFields)
    },
    removeObjectFromAccount: (context, object) => {
        if(!object.hasOwnProperty('FinalFields')) return console.warn('Delete object without FinalFields Object')
        console.log('Remove Object From account please...')
        object.FinalFields.index = object.LedgerIndex
        context.commit('removeObject', object.FinalFields)
    },
}

const mutations = {
    setAccount: (state, account) => {
        state.address = account
    },
    setAccountInfo: (state, obj) => {
        state.accountInfo = obj
    },
    setAccountObjects: (state, arr) => {
        state.accountObjects = arr
    },
    mergeAccountObjects: (state, arr) => {
        state.accountObjects = state.accountObjects.concat(arr)
    },
    setAccountTransactions: (state, arr) => {
        state.accountTransactions = arr
    },
    modifyAccountObject: (state, node) => {
        if(state.accountInfo?.index === node.index && node.index) {
            state.accountInfo = _merge(state.accountInfo, node)
            console.log('AccountRoot changed')
        }
    },
    modifyObject: (state, node) => {
        for(let i = 0; state.accountObjects.length > i; i++) {
            if(state.accountObjects[i].index === node.index) {
                state.accountObjects[i] = _merge(state.accountObjects[i], node)
                console.log(node)
                console.log('Modify Done...')
                break
            }
        }
    },
    addObject: (state, object) => {
        state.accountObjects.push(object)
    },
    removeObject: (state, objectToDelete) => {
        state.accountObjects = state.accountObjects.filter(object => {
            if(object.index === objectToDelete.index) return false
            else return true
        })
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
