<template>
    <template v-if="comp === 'compose' || comp === 'view'">
        <Transition>
            <SelectTransactionType v-if="(showTypeSelect || !activeTemplate) && comp === 'compose'" @selected="setTemplate" :options="templateNames" @close="closeTransactionSelect"/>
        </Transition>
        <template v-if="activeTemplate || comp === 'view'">
            <nav class="transaction-header-row">
                <a class="btn" @click="comp === 'view' ? $router.replace('/transactions') : $router.go(-1)">
                    <fa :icon="['fas', 'chevron-left']"/>
                </a>
                <div>
                    <h2>{{ headerTitle }}</h2>
                </div>
                <a class="btn" @click="showTypeSelect = !showTypeSelect" v-if="comp === 'compose' && this.hasTicketsAvailable">
                    <fa :icon="['fas', 'list']" />
                </a>
                <a v-else class="btn" @click="typeof submitResult === 'string' ? openTxViewer() : null">
                    <img src="../assets/svg/arrow.up.right.circle.fill.svg" v-show="typeof submitResult === 'string'" class="invert-color">
                </a>
            </nav>

            <Spinner v-if="loading && comp === 'view'" style="margin: auto;"/>

            <div v-else-if="(error.transaction || !transaction)  && comp === 'view'" class="error-transaction">
                <img src="../assets/svg/exclemationmark.triangle.fill.svg" style="width: 50px; margin-bottom: 20px;" @click="getTransaction()">
                <h3>Error with transaction</h3>
                <code>ID: {{ txId }}</code>
            </div>

            <div v-else-if="comp === 'compose'" class="tx-container">
                <keep-alive>
                    <component :is="activeComponent" view="compose"/>
                </keep-alive>
            </div>
            
            <div v-else-if="comp === 'view'" class="view-transaction">
                <div style="height: inherit; padding: 0 10px; display: flex; flex-direction: column;">
                    <label v-if="transaction?.submit_result" class="submit-result-label" :style="{ 'background-color': submitResult === 'tesSUCCESS' ? 'var(--green)' : 'var(--red)' }">{{ submitResult }}</label>
                    <label v-else-if="transaction?.reject_tx?.submit_result" class="submit-result-label" style="background-color: var(--red);">Rejected</label>
                    <label style="text-align: center;">#{{ transaction.txjson.TicketSequence || transaction.txjson.Sequence }}</label>
                    <div>
                        <keep-alive>
                            <component :is="activeComponent" view="view" :transaction="transaction"/>
                        </keep-alive>
                    </div>
                    <ToSignList :transaction="transaction"/>
                </div>
            </div>
        </template>
    </template>
    <div v-else-if="comp === 'listItem' && transaction" class="list-item-container"  @click="$router.push({ name: 'ViewTx', params: { comp: 'view', id: transaction._id, transaction }} )">
        <div v-if="transaction.submit_result !== 'tesSUCCESS' && transaction.hasOwnProperty('tx_hash')" class="warning-header">
            <img src="../assets/svg/exclemationmark.circle.fill.svg">
            Failed Transaction Result
        </div>
        <div v-else-if="!transaction.submit_result && !transaction.reject_tx && $store.getters.getSequenceStatus(transaction.txjson.TicketSequence || transaction.txjson.Sequence)" class="warning-header">
            <img src="../assets/svg/exclemationmark.circle.fill.svg">
            {{ $store.getters.getSequenceStatus(transaction.txjson.TicketSequence || transaction.txjson.Sequence) }}
        </div>
        <div class="transaction-list-item-header">
            <h4 style="font-size: 1.2rem;">{{ transaction.txjson.TransactionType }}</h4>
            <h3>{{ getSignerWeightCombined(transaction.signed_txs) }}/{{ SignerQuorum }}</h3>
        </div>
        <div class="transaction-list-item-sub-header">
            <h6 v-if="transaction.txjson.TicketSequence || transaction.submit_result">#{{ !transaction.submit_result ? transaction.txjson.TicketSequence : transaction.txjson.TicketSequence || transaction.txjson.Sequence }}</h6>
            <h6 v-else-if="transaction.txjson.Sequence">
                #{{ transaction.txjson.Sequence }}
                <h6>
                    <img src="../assets/svg/exclemationmark.circle.fill.svg" style="height: 0.6rem;">
                    No Ticket, Using Sequence.
                </h6>
            </h6>
            <h6 v-else>
                <img src="../assets/svg/exclemationmark.circle.fill.svg" style="height: 0.6rem;">
                No Sequence Available
            </h6>
            <h6 style="margin-bottom: auto;">{{ transaction.CREATED_AT }}</h6>
        </div>

        <keep-alive>
            <component :is="activeComponent" view="listItem" :transaction="transaction"/>
        </keep-alive>

        <hr style="margin: 10px 0;">
        <ProgressTx v-if="!submitResult" :tx="transaction"/>
        <label v-else-if="typeof transaction.submit_result === 'string'" class="submit-result-label" :style="{ 'background-color': transaction?.submit_result === 'tesSUCCESS' ? 'var(--green)' : 'var(--red)' }">{{ transaction?.submit_result }}</label>
        <label v-else-if="transaction?.reject_tx?.submit_result" class="submit-result-label" style="background-color: var(--red);">Rejected</label>
    </div>
</template>

<script>
import Spinner from '@/components/Spinner.vue'
import SelectTransactionType from '@/components/SelectTransactionType.vue'
import ToSignList from '@/components/ToSignList.vue'
import ProgressTx from '@/components/ProgressTx.vue'
import mixin from '../mixins/multiSign'
import xapp from '../plugins/xapp'

export default {
    components: { Spinner, SelectTransactionType, ToSignList, ProgressTx },
    props: {
        comp: {
            validator(value) {
                return ['compose', 'view', 'listItem'].includes(value)
            },
            default: 'view'
        },
        txId: String
    },
    mixins: [mixin],
    data() {
        return {
            activeTemplate: null,
            showTypeSelect: false,
            transactionData: null,
            loading: false,
            error: {
                transaction: false
            }
        }
    },
    computed: {
        transaction() {
            return this.$store.getters.getTransactionById(this.txId) || this.transactionData
        },
        hasTicketsAvailable() {
            const unique = this.$store.getters.getUnusedTickets
            return unique.length > 1
        },
        templateNames() {
            const list = Object.keys(this.templateCopmonents)
            return list
        },
        templateCopmonents() {
            const requireContext = require.context('@/components/tx_templates', false, /.*\.vue$/)

            const dynamicComponents = requireContext.keys()
                .map(file =>
                    [file.replace(/(^.\/)|(\.vue$)/g, ''), requireContext(file)]
                )
                .reduce((components, [name, component]) => {
                    components[name] = component.default || component
                    return components
                }, {})
            return dynamicComponents
        },
        activeComponent() {
            if(this.comp === 'compose') return this.templateCopmonents[this.activeTemplate]
            else {
                if(this.transaction == null || typeof this.transaction?.txjson?.TransactionType == 'undefined'){
                    return this.error.transaction = true
                }
                else return this.templateCopmonents[this.transaction.txjson.TransactionType]
            }
        },
        submitResult() {
            return this.transaction?.submit_result || this.transaction?.reject_tx?.submit_result || null
        },
        headerTitle() {
            let title = this.activeTemplate || this.transaction?.txjson?.TransactionType
            if(!title) title = this.loading === true ? '---' : 'Error'
            return title
        }
    },
    methods: {
        setTemplate(value) {
            this.activeTemplate = value || this.activeTemplate
            this.showTypeSelect = false
        },
        closeTransactionSelect() {
            this.showTypeSelect = false
            if(!this.activeTemplate) this.$router.replace('/transactions')
        },
        openExternalBrowser(url) {
            return xapp.openExternalBrowser(url)
        },
        openTxViewer() {
            return xapp.openTxViewer(this.transaction.tx_hash, this.$store.getters.getAccount)
        },
        async getTransaction() {
            let tx = this.$store.getters.getTransactionById(this.txId)
            if(tx && tx != null) return tx
            else {
                return await this.$store.dispatch('getTransactionById', this.txId)
            }
        }
    },
    async created() {
        this.loading = true
        if(this.comp === 'compose') {
            if(!this.hasTicketsAvailable) this.activeTemplate = 'TicketCreate'
            else this.showTypeSelect = true
        } else {
            try {
                // todo add watcher to check if prop txId has been changed
                const tx = await this.getTransaction()
                if(!tx || tx == null) throw new Error('No transaction')
                else this.transactionData = tx
            } catch(e) {
                console.error('Error with getting Transaction', e)
                this.error.transaction = true
            }
        }
        this.loading = false
    }
}
</script>

<style>
.tx-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0 20px;
    margin-bottom: 30px;
    align-items: center;
}
.tx-container > form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
}
.transaction-header-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.transaction-header-row a {
    max-width: 40px;
}
.transaction-list-item-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.transaction-list-item-sub-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: var(--grey);
}
.list-item-container {
    display: flex;
    flex-direction: column;
}
.warning-header {
    text-align: center;
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: bold;
    margin: 0 auto;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--orange);
}
.warning-header > img {
    height: 15px;
}
.view-transaction {
    display: flex;
    flex: 2 auto;
    flex-direction: column;
    padding-bottom: 30px;
}
.error-transaction {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
}
.submit-result-label {
    padding: 5px 10px;
    font-size: 0.8rem;
    font-weight: bold;
    border-radius: 10px;
    margin: 0 auto;
}
.account-picker {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    border: 1px solid var(--var-border);
    border-radius: 10px;
}
.account-picker > .account-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 10px 10px;
}
.account-container {
    text-align: left;
}
.account-picker > .label {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    background-color: var(--var-primary);
    border-left: 1px solid var(--var-border);
    border-radius: 0 10px 10px 0;
}
/* fieldset {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 10px;
    max-width: 300px;
} */
fieldset label {
    text-wrap: nowrap;
}
fieldset select {
    margin-left: auto;
    max-width: 150px;
    min-width: max-content;
    width: 120px;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
