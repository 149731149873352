import axios from 'redaxios'
import jwtDecode from "jwt-decode"

let ott
let tokenData
let jwt
let curatedAssets

const promises = {
    ott: null
}

// const apiEndPoint = 'https://xumm.app/api/v1/xapp-jwt'
const apiEndPoint = process.env.VUE_APP_API_ENDPOINT
const apiKey = process.env.VUE_APP_XAPP_KEY

const headers = () => {
    return { headers: { Authorization: `Bearer ${jwt}` } }
}

const setOtt = () => {
    const urlParams = new URLSearchParams(window.location.search)
    ott = process.env.VUE_APP_DEV_MODE?.toLowerCase() == 'true' && !urlParams.get('xAppToken') ? process.env.VUE_APP_OTT_DEV : urlParams.get('xAppToken')
}

const getTokenData = async (newOtt) => {
    if(typeof newOtt === 'string') ott = newOtt
    if(!tokenData && ott) {
        try {
            if(!promises.ott || promises.ott === null) promises.ott = setTokenData()
            const res = await promises.ott
            promises.ott = null
            return res
        } catch(e) {
            throw 'Error getting Token Data'
        }
    } else if(tokenData) {
        return tokenData
    } else {
        throw 'No data no token'
    }
}

const setTokenData = async () => {
    const res = await axios.get(`${apiEndPoint}/xapp/ott/${ott}`, { headers: { 'x-api-key': apiKey, 'x-api-ott': ott } })
    tokenData = res.data
    jwt = res.data.token
    
    return tokenData
}

const getAccountMeta = async (account) => {
    const res = await axios.get(`https://xumm.app/api/v1/platform/account-meta/${account}`)
    return res.data
}

const openSignRequest = async (uuid) => {
    try {
        await window.xApp.openSignRequest({ uuid })
    } catch(e) {
        alert(e)
        throw e
    }
}

const closeXapp = async () => {
    try {
        await window.xApp.close({ refreshEvents: false })
    } catch(e) {
        throw e
    }
}

const navigateXapp = async (id) => {
    if(!id || typeof id !== 'string') throw new Error('Identifier is not of type string')
    try {
        window.xApp.navigate({ xApp: id })
    } catch(e) {
        throw e
    }
}

const openExternalBrowser = async (url) => {
    try {
        await window.xApp.openBrowser({ url: url })
    } catch(e) {
        throw e
    }
}

const openTxViewer = async (tx, account) => {
    try {
        await window.xApp.tx({
            tx,
            account
        })
    } catch(e) {
        throw e
    }
}
const destinationSelect = async () => {
    try {
        await window.xApp.selectDestination()
        const res = await new Promise((resolve, reject) => {
            window.xApp.on('destination', (res) => {
                resolve(res)
            })
        })
        return res
    } catch (e) {
        if (e === 'USER_CLOSED') throw { msg: 'closed', error: false }
        throw e
    }
}

const getCuratedAssets = async () => {
    if(curatedAssets && Object.keys(curatedAssets).length > 0 && curatedAssets.constructor === Object) return curatedAssets
    try {
        const res = await axios.get(`${apiEndPoint}/curated-assets`, headers())
        curatedAssets = res.data
        return curatedAssets
    } catch(e) {
        throw e
    }
}

const status = () => {
    return new Promise((resolve, reject) => {
        function message(event) {
            window.removeEventListener('message', message)
            document.removeEventListener('message', message)

            const data = JSON.parse(event.data)
            switch(data.method) {
                case 'payloadResolved':
                    if(data.reason === 'SIGNED') return resolve(data)
                case 'selectDestination':
                    if(data.reason === 'SELECTED') return resolve(data)
                case 'scanQr':
                    if(data.reason === 'SCANNED') return resolve(data)
                // default:
                    // return reject(data.reason)
            }
            return reject(data.reason)
        }
        //iOS
        window.addEventListener('message', message)
        //Android
        document.addEventListener('message', message)
    })
}

const payload = async (payload, useMultiSign) => {
    try {
        const res = await axios.post(`${apiEndPoint}/payload`, payload, headers())
        openSignRequest(res.data.uuid)
        await status()
        if(!useMultiSign) {
            const result = await axios.get(`${apiEndPoint}/payload/${res.data.uuid}`, headers())
            return result.data
        } else {
            return res.data.uuid
        }
    } catch(e) {
        if (e === '') throw { msg: 'closed', error: false }
        throw e
    }
}

const versionCheck = (v1, v2) => {
    var v1parts = v1.split('.');
    var v2parts = v2.split('.');

    // First, validate both numbers are true version numbers
    function validateParts(parts) {
        for (var i = 0; i < parts.length; ++i) {
            if (!/^\d+$/.test(parts[i])) {
                return false;
            }
        }
        return true;
    }
    if (!validateParts(v1parts) || !validateParts(v2parts)) {
        return NaN;
    }

    for (var i = 0; i < v1parts.length; ++i) {
        if (v2parts.length === i) {
            return 1;
        }

        if (v1parts[i] === v2parts[i]) {
            continue;
        }
        if (v1parts[i] > v2parts[i]) {
            return 1;
        }
        return -1;
    }

    if (v1parts.length != v2parts.length) {
        return -1;
    }

    return 0;
}

const openScanner = async () => {
    try {
        await window.xApp.scanQr()
        const res = await new Promise((resolve, reject) => {
            window.xApp.on('qr', (res) => {
                resolve(res)
            })
        })
        return res
    } catch(e) {
        throw e
    }
}

const updateJwt = async (token) => {
    jwt = token
}

const authenticateBySignerLogin = async (signerList) => {
    console.log('attempt to: Authenticate with signer account login')
    try {
        const UUID = await payload(
            {
                txjson: { TransactionType: "SignIn" },
                options: {
                    signers: signerList
                }
            }, true)
        const authTokens = await axios.post(`${apiEndPoint}/authenticate`, { uuid: UUID }, headers())
        jwt = authTokens.data.accessToken
        return jwtDecode(jwt)
    } catch(e) {
        console.error(e)
        throw e
    }
}

const test = 'hi'

const getter = (data) => {
    if(data === 'tokenData') return tokenData
    else null
}

export default {
    getter,
    setOtt,
    getTokenData,
    getAccountMeta,
    closeXapp,
    navigate: navigateXapp,
    signPayload: payload,
    getCuratedAssets,
    openExternalBrowser,
    openTxViewer,
    openScanner,
    destinationSelect,
    versionCheck,
    headers,
    updateJwt,
    authenticateBySignerLogin
}
